import React, {Component} from 'react';

import {
	Button,
} from '@material-ui/core';

import {Close} from '@material-ui/icons';

export default class BaseApp extends Component {
	constructor(...args){
		super(...args);
		
		this.messagePage = {
			title: '',
			body:''
		};

		this.rightClickParams = {
			anchorEl:null,
			options:[],
			onClose:() => {
				this.rightClickParams.anchorEl = null;
				this.forceUpdate();
			}
		}

		this.simpleDialogParams = {
			open: false,
			onClose:() => {
				if(this.simpleDialogParams.preventClose){
					return;
				}
				this.simpleDialogParams.open = false;
				if(this.simpleDialogParams.afterClose){
					this.simpleDialogParams.afterClose();
				}
				this.forceUpdate();
			}
		}

		this.fullScreenDialogParams = {
			open: false,
			onClose:() => {
				this.fullScreenDialogParams.open = false;
				this.forceUpdate();
			}
		}
	}

	openDialog({label, Component, afterClose = null, preventClose=false, loading=false, width='500px'}){
		this.simpleDialogParams.open = true;
		this.simpleDialogParams.label = label;
		this.simpleDialogParams.Component = Component;
		this.simpleDialogParams.afterClose = afterClose;
		this.simpleDialogParams.loading = loading;
		this.simpleDialogParams.preventClose = preventClose;
		this.simpleDialogParams.width = width;

		this.forceUpdate();
	}

	async alert({label, content}){
		return new Promise( (resolve, reject)=>{
		let Component = ()=>(<div>{content}<div style={{marginTop:'30px', textAlign:'right'}}><Button variant='raised' color='primary' onClick={()=>{this.closeDialog(); resolve(true);}}>OK</Button></div></div>);
			this.openDialog({label, Component, preventClose:true});
		});
	}

	loadingDialog(){
		this.simpleDialogParams.loading = true;
		this.forceUpdate();
	}

	closeDialog(){
		this.simpleDialogParams.open = false;
		this.forceUpdate();
	}

	openFullScreenDialog({label, Component, hideClose=false }){
		this.fullScreenDialogParams.open = true;
		this.fullScreenDialogParams.label = label;
		this.fullScreenDialogParams.Component = Component;
		this.fullScreenDialogParams.loading = false;
		this.fullScreenDialogParams.hideClose = hideClose;
		this.forceUpdate();
	}

	loadingFullScreenDialog(){
		this.fullScreenDialogParams.loading = true;
		this.forceUpdate();
	}

	closeFullScreenDialog(){
		this.fullScreenDialogParams.open = false;
		this.forceUpdate();
	}

	showContextMenu({anchorEl, options}){
		this.rightClickParams.anchorEl = anchorEl;
		this.rightClickParams.options = options;
		this.forceUpdate();
	}

	closeContextMenu(){ this.rightClickParams.onClose(); }

	showFlashMessage({label, color = 'green', autoClose = 0}){
		this.flashParams = {
			anchorOrigin: {vertical:'top', horizontal:'center'},
			open: true,
			SnackbarContentProps: {style: {minWidth:'30px', color:'white', backgroundColor:`var(--${color})`}}, 
			message: label,
			action: <Button style={{minWidth:'30px', color:'white', padding:0}} onClick={()=>{ this.closeFlashMessage(); }} size="small"><Close /></Button>
		}

		if(autoClose){
			setTimeout(()=>{ this.closeFlashMessage(); }, autoClose * 1000);
		}

		this.forceUpdate();
	}

	closeFlashMessage(){
		this.flashParams.open = false;
		this.forceUpdate();
	}

	startLoadingForm(form){
		form.errorMessage = '';
		form.loading = true;
		this.forceUpdate();
	}

	stopLoadingForm(form, errorMessage){
		errorMessage = (errorMessage && errorMessage.toString) ? errorMessage.toString() : errorMessage;
		form.errorMessage = errorMessage;
		form.loading = false;
		this.forceUpdate();
	}

	validate(result, errorMessage){
		if (!result) { throw new Error(errorMessage); }
	}

	fuzzyMatch(rows = [], pattern = '', getText=function(row){} ){
		if(!pattern) {return rows;}
		pattern = pattern.toLowerCase().split("").reduce(function(a,b){ return a+".*"+b; });

		return rows.filter( row => {
			let contents = getText(row) || '';
			contents = contents.toLowerCase();
			return (new RegExp(pattern)).test(contents);
		});
	};

	goToMessagePage({title, body}){
		this.messagePage.title = title;
		this.messagePage.body = body;
		this.history.push('/message');
	}

	localStorage(key, value){
		if(value !== undefined){
			// Put the object into storage
			window.localStorage.setItem(key, JSON.stringify(value));
		}else{
			return JSON.parse(window.localStorage.getItem(key));
		}
	}
}