/* eslint jsx-a11y/anchor-is-valid:0 */
import React, {Component, Fragment} from 'react';
//import logo from './logo.svg';
import './App.css';
import quotes from './quotes';

/*
	upload images.
		Need to fix the previously uploaded images in journal. 
		Would be good to have a prefix on the files, so you know who uploaded them. Optional prefix for id?	
	Show loading symbols
	scroll to the jid on page refresh
	Create a new login? 
*/







// class App extends Component {
//   render() {
//     return (
//       <div className="App">
//         <header className="App-header">
//           <img src={logo} className="App-logo" alt="logo" />
//           <p>
//             Edit <code>src/App.js</code> and save to reload.
//           </p>
//           <a
//             className="App-link"
//             href="https://reactjs.org"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Learn React
//           </a>
//         </header>
//       </div>
//     );
//   }
// }






import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import { withStyles } from '@material-ui/core/styles';
import BaseApp from './BaseApp'

import {Close, Edit, Delete, Menu as MenuIcon, Error as ErrorIcon} from '@material-ui/icons';

import {
	Button,
	colors,
	createMuiTheme,
	CssBaseline,
	CircularProgress,
	Menu,
	MenuItem,
	Snackbar,
	Dialog,
	//DialogActions,
	DialogContent,
	//DialogContentText,
	DialogTitle,
	IconButton,
	MuiThemeProvider,
	TextField,
	//Typography,
	//withStyCloseIcon,
  Slide,
  } from '@material-ui/core';

import Wait from './components/Wait';



  const theme = createMuiTheme({
	palette: {
	  primary: {
		light: colors.blue['A200'],
		main: colors.blue['A400'],
		dark: colors.blue['A700'],
	  },
	  secondary: {
		light: colors.green[300],
		main: colors.green[500],
		dark: colors.green[700],
	  },
	},
});

const styles = theme => ({
	// root: {
	//   textAlign: 'center',
	//   paddingTop: theme.spacing.unit * 20,
	// },
	// icon: {
	//   marginRight: theme.spacing.unit,
	// },
});






class App extends BaseApp{
	constructor(...args){
		super(...args);
		this.state = {};
		this.rows = [];
		this.quote = _.sample(quotes);
		this.load();
		this.resetWithBlankPost();
		this.clickedNavLink = this.clickedNavLink.bind(this);

		setInterval(this.focusNavLink.bind(this), 500);

		setInterval(this.saveDraft.bind(this), 30000);

		window.app = this;
		this.app = this;
		this.showDrafts = false;
		this.loginRefs = {};
		this.loading = true;
	}

	get menuOptions(){
		return [
			this.loggedIn ? {label: 'Log out', onClick:this.logout.bind(this) } : {label: 'Log in', onClick:this.login.bind(this) },
			this.showDrafts ? {label: 'Hide Drafts',  onClick:() => {this.showDrafts = false; this.load();}} :
			{label: 'View Drafts', onClick:() => {this.showDrafts = true; this.load();}},
			{label: 'Delete all drafts', onClick:this.deleteDrafts.bind(this)},
		];
	}

	async deleteDrafts(){
		if(!window.confirm('Are you sure you want to delete all the drafts?')){
			return;
		}
		let result = await this.ajax('deleteDrafts');
		if(result.result === true){
			alert('Deleted all drafts.')
		}
		this.load();
	}

	async onLoginSubmit({email, password}){
		this.loginLoading = true;
		this.loginError = null;
		this.forceUpdate();
		let result = await this.ajax('login', {username:email, password:password});
		this.loginLoading = false;

		if(result.result === true){
			this.load();
			return;
		}

		this.loginError = 'Invalid email or password.';
		this.forceUpdate();
	}

	async login(){
		let result = await this.ajax('login', {username:prompt('username'), password:prompt('password')});

		if(result.result === true){
			this.load();
		}
	}

	async logout(){
		this.resetWithBlankPost();
		let result = await this.ajax('logout');
		this.load();
	}

	editPost(row){
		let rowCopy = {...row};
		this.openDialog({
			xxxlabel:'Hello World',
			Component: () => <EditPost post={rowCopy} app={this} forceUpdate={this.forceUpdate.bind(this)} />,
			afterClose:null,
			preventClose:true,
			loading:false,
			width:'800px'
		});
	}

	cancelEditPost(post){
		if(!post.jid){
			this.resetWithBlankPost();
		}else{
			
		}
		this.closeDialog();
	}

	resetWithBlankPost(){
		this.post = {
			created: moment(),
			body: '',
			jid:'',
			lastSavedDraft: '',
		};
	}

	async getAccountDetails(){
		let result = await this.ajax('accountInfo');
		this.loggedIn = result.loggedIn;
		this.username = result.userName;
		this.userId = result.userId;
		this.uploadKey = result.uploadKey;
	}

	async load(){
		
		await this.getAccountDetails();

		if(!this.loggedIn){ this.loading = false; this.forceUpdate(); return;}

		this.loading = true;

		this.forceUpdate();

		//let rows = await fetch('js/journal.js', { method: 'get' }).then( response => response.json() );
		let data = {isdraft:this.showDrafts};
		let rows = await this.ajax('selectAll', data);
		rows.forEach( row => {
			row.created = moment(row.created);
			row.navId = row.created.format('YYYY-MM')
			row.lastSavedDraft = row.body;
			row.formattedBody = row.body.replace(/##([a-zA-Z0-9._/?&=:]*).wav##/g, `WAV_FILE`);
			row.formattedBody = row.formattedBody.replace(/##([a-zA-Z0-9._/?&=:]*).mp3##/g, `MP3 FILE`);
			row.formattedBody = row.formattedBody.replace(/##([a-zA-Z0-9._]*)##/g, `<img src="https://files.jonnymoon.com/?id=$1&width=800&height=500&crop=shrink" />`);
			row.formattedBody = row.formattedBody.replace(/##([a-zA-Z0-9._/?&=:]*)##/g, `<img src="$1" />`);
			//
		});
		rows = _.sortBy(rows, 'created').reverse();
		this.rows = rows;
		this.loading = false;
		this.forceUpdate();
	}

	ajax(action, data={}){
		// you need the credentials include for the cookie to be included in the request headers.
		//return fetch('http://localhost/dev/journal/src/ajax.php', { credentials: 'include', method: 'post', body: JSON.stringify({action, body:data})}).then( response => response.json() );
		return fetch('ajax.php', { credentials: 'include', method: 'post', body: JSON.stringify({action, body:data})}).then( response => response.json() );
	}

	async saveDraft(){
		let post = this.post;
		if (post.body.length === 0 || post.body === post.lastSavedDraft) {
			return;
		}

		post.lastSavedDraft = post.body;
		let result = await this.ajax('edit', {
			title:    post.title || 'Untitled',
			body:     post.body,
			created:  post.created,
			jid:      post.jid,
			isdraft:  true,
		});
		
		console.log('Saving draft', result);
		this.forceUpdate();
	}

	async submit(post){
		let result = await this.ajax('edit', {
			title:    post.title,
			body:     post.body,
			created:  post.created,
			jid:      post.jid,
		});
		console.log(result);

		if(result.result === true){
			this.resetWithBlankPost();
			this.closeDialog();
			this.load();
			return;
		}

		alert(result.error);
	}

	

	async delete(jid){
		if (!window.confirm('Are you sure you want to delete?')) {return;}
		let result = await this.ajax('delete', {
			jid:    jid
		});
		alert(result.result);
		this.load();
	}

	updateNavigation(rows){
		let navigation = {};
		rows.forEach(row => {
			let formattedDate = row.created.format('YYYY-MM')
			if(!navigation[formattedDate]){
				navigation[formattedDate] = 0;
			}
			navigation[formattedDate]++;
		});
		this.navigationLinks =  _.sortBy(_.map(navigation, ((o,k) => {return {date:k, dateFormatted: moment(k, 'YYYY-MM').format('MMM YYYY'), value:o};})), 'date').reverse();
	}

	clickedPost(row){
		this.currentPost = row;
		this.forceUpdate();
	}

	clickedNavLink(date){
		let row = this.rows.find( row => { return row.created.format('YYYY-MM') === date });
		if(row){
			document.location = `#jid${row.jid}`
		}
	}

	focusNavLink(){
		let positions = $('[data-navlink-id]').map((k, o) => {return {el: o, y:o.getBoundingClientRect().y}}).toArray();
    let item = positions.find( item => item.y > 0);
    if(!item){ return; }
		let id = $(item.el).data('navlink-id');

		if(this.lastNavLinkId === id){
			return;
		}

		this.lastNavLinkId = id;

		$('.navlink.selected').removeClass('selected');
		$(`.navlink_${id}`).addClass('selected');
	}


	render(){
		let currentMonthDay = this.currentPost ? this.currentPost.created.format('MMDD') : moment().format('MMDD')
		let similarPosts = [];

		let filteredRows = this.rows.filter( row => {
			if(row.created.format('MMDD') === currentMonthDay && row !== this.currentPost){
				similarPosts.push(row);
			}

			if(this.state.search){
				let search = this.state.search.toLowerCase().split(' ');

				let title = row.title.toLowerCase();
				let body = row.body.toLowerCase();

				while(search.length){
					let str = search.pop();
					if(title.indexOf(str) === -1 &&  body.indexOf(str) === -1){ return false; }
				}

				return true;
			}

			return true;
		});


		this.updateNavigation(filteredRows);


		//similarPosts.reverse();

		return (
			<MuiThemeProvider theme={theme}>
      		<CssBaseline />
			<div>
				<div className="header noPrint">
					<div className="header-content">
						<div className="logo">Life</div>
						<div className='quote'>{this.quote}</div>
						{this.loggedIn && 
							<Fragment>
								<div>
									<input className="search" value={this.state.search || ''} onChange={e => this.setState({search:e.currentTarget.value})} type="text" placeholder="Search"  />
								</div>
								
								<div style={{margin:'12px 10px 0 0'}}><LongMenu options={this.menuOptions} /></div>
							</Fragment>
						}
					</div>
				</div>
				<div className="content" style={{display:'flex'}}>
					{!this.loggedIn && <LoginScreen loading={this.loginLoading} errorMessage={this.loginError} refs={this.loginRefs} onSubmit={this.onLoginSubmit.bind(this)} />}
					{this.loggedIn && <Fragment>
						<div className="nav" style={{width:'200px', padding:20}}>
							{this.navigationLinks.map(item => (
								<NavigationLink key={item.dateFormatted} item={item} onClick={this.clickedNavLink} />
							))}
						</div>	
						<div  className="centerContent" style={{flex:1}}>
							<div className="noPrint">
								<EditPost app={this} post={this.post} forceUpdate={this.forceUpdate.bind(this)}/>
							</div>
							

							{filteredRows.map( row => 
								<Post app={this} key={row.jid} row={row} onClick={this.clickedPost.bind(this)} />
							)}	
						</div>	
						<div  className="similarPosts" style={{width:'300px', background:'#f1f1f1'}}>
							{similarPosts.map( row => 
								<SimilarPost key={row.jid} row={row} />
							)}
						</div>	
					</Fragment>
					}
				</div>
			</div>
			{ !!this.loading && <Wait />}
			<SimpleDialog {...this.app.simpleDialogParams} />
			<FullScreenDialog {...this.app.fullScreenDialogParams} app={this.app} />
			<Snackbar {...this.app.flashParams} app={this.app}/>
			</MuiThemeProvider>
		)
	}
}

App = withStyles(styles)(App);

function NavigationLink(props){
	return (
		<a className={`navlink navlink_${props.item.date}`} onClick={() => { props.onClick(props.item.date) }}>{props.item.dateFormatted} <span style={{color:'rgb(142, 142, 142)'}}>({props.item.value})</span></a>
	);
}

function EditPost(props){
	let noChanges = props.post.lastSavedDraft === props.post.body;
	return (
		<div style={{margin:50}}>
			<Text label="Title" value={() => props.post.title} onChange={e => {props.post.title = e.target.value;}} fullWidth />
			<input type="date" value={props.post.created.format('YYYY-MM-DD')} required onChange={ e => {props.post.created = moment(e.currentTarget.value); props.forceUpdate()}} style={{marginBottom:'5px'}} />
			<Text 
				multiline
				InputLabelProps={{
					shrink: true,
				}}
				variant="outlined"
				rows="20"
				label={props.post.created.format('dddd, LL')}
				value={() => props.post.body}
				onChange={e => {props.post.body = e.target.value; if(noChanges){props.forceUpdate();}}}
				fullWidth
				style={{fontSize:14, fontFamily:'var(--font)'}} />
			<div style={{textAlign:'right', marginTop:'5px', position:'relative'}}>
				{ props.post.lastSavedDraft !== props.post.body &&
					<div style={{position:'absolute', right:0, top:'-12px', fontSize:12, color:'gray'}}>There are unsaved changes.</div>
				}
				<div style={{display:'flex', flexDirection:'row'}}>
					<Uploader style={{flex:1, marginRight:20}} userId={props.app.userId} uploadKey={props.app.uploadKey} onUpload={(result) => {  
						if(!result.result){ return; }
						/* {"result":"success","id":"5bd475bd08810.jpg","path":"http:\/\/files.jonnymoon.com\/?id=5bd475bd08810.jpg&"} */
						/* props.post.body += '\n' + result.filenames.map(filename => `##${filename}##`).join('\n'); */
						const path = result.path.replace('http:', 'https:')
						props.post.body += '\n' + `##${path}width=800&height=500&crop=shrink##`;
						props.forceUpdate();
					}} />
					<div style={{whiteSpace:'nowrap', paddingTop:10}}>
						<Button variant="contained" style={{marginRight:20}} onClick={props.app.cancelEditPost.bind(props.app, props.post)}>Cancel</Button>
						<Button variant="contained" color="primary" onClick={props.app.submit.bind(props.app, props.post)}>Submit</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

function Post({row, onClick, app}){
	return (
		<div data-navlink-id={row.navId} style={{margin:50}} onClick={ e => onClick(row,e)}>
			<div style={{borderBottom:'solid 1px #dedede', fontSize:'20px'}}>
				<div style={{display:'flex', flexDirection:'row'}}>
					<div style={{flex:1}}>
						<a name={`jid${row.jid}`}>{row.title}</a>
						<div style={{fontSize:'11px', color:'rgb(142, 142, 142)'}}>
							{row.created.format('dddd, LL')} {!!row.author && <span>- Email from {row.author}</span>}
						</div>
					</div>	
					<div className="noPrint">
						<IconButton onClick={app.editPost.bind(app, row)}><Edit size="small" /></IconButton>
						<IconButton onClick={app.delete.bind(app, row.jid)}><Delete size="small" /></IconButton>
					</div>
				</div>
			</div>
			<div style={{lineHeight:'175%', whiteSpace:'pre-line', wordBreak: 'break-word'}} dangerouslySetInnerHTML={{ __html: row.formattedBody }}>

			</div>
		</div>
	);
}

function SimilarPost({row}){
	return (
		<div style={{margin:'0 15px 15px 15px', padding:'15px'}}>
			<div style={{borderBottom:'solid 1px #dedede'}}>
				<a href={`#jid${row.jid}`} style={{ color:'var(--selected)', textDecoration: 'none', fontSize:18}}>{row.title}</a>
				<div style={{fontSize:'11px', color:'rgb(142, 142, 142)'}}>{row.created.format('dddd, LL')} {!!row.author && <span>- Email from {row.author}</span>}</div>
			</div>
			<div style={{lineHeight:'175%', fontSize:12, whiteSpace:'pre-line', paddingRight:15, wordBreak: 'break-word', maxHeight:'300px', overflow:'auto'}} dangerouslySetInnerHTML={{ __html: row.formattedBody }}>
			</div>
		</div>
	);
}

// function Button(props){
// 	return <div style={{backgroundColor:props.color && `var(--${props.color})`, color:props.color && `white`}} onClick={props.onClick} className="button">{props.label}</div>;
// }

class Uploader extends Component {

	componentDidMount(){


		document.body.addEventListener("drop", ( event ) =>  {
			document.body.classList.remove('drag-file-over')
			this.div.classList.remove('hover');
		});

		document.body.addEventListener("dragover", ( event ) => {
			if(_.get(event,'dataTransfer.types[0]') !== 'Files'){
				return;
			}
			document.body.classList.add('drag-file-over')
		});
 
		this.div.addEventListener("dragover", ( event ) => {
			if(_.get(event,'dataTransfer.types[0]') !== 'Files'){
				return;
			}
			this.div.classList.add('hover')
		});

		this.div.addEventListener("dragleave", ( event ) => {
			this.div.classList.remove('hover')
		});

		function removeClass(event){
			if(event.screenX === 0 && event.screenY === 0){
				document.body.classList.remove('drag-file-over')
			}
		}

		document.body.addEventListener("dragleave",removeClass);
		document.body.addEventListener("dragend",removeClass);
		document.body.addEventListener("dragexit",removeClass);
	}


	async upload(){
		this.loading = true;
		this.forceUpdate();
		
		const files = this.input.files;
		var formData = new FormData();
		for (var i = 0; i < files.length; i++) {
			var file = files[i];
		  
			// Check the file type.
			//if (!file.type.match('image.*')) { continue;}
		  
			// Add the file to the request.
			formData.append('file', file, file.name);
			formData.append('session', this.props.uploadKey);
			//formData.append('file[]', file, file.name);
			//formData.append('uid', this.props.userId);
		}

		let result = await new Promise((resolve, reject) => {
			// Set up the request.
			var xhr = new XMLHttpRequest();
			// Open the connection.
			xhr.open('POST', 'https://files.jonnymoon.com/index.php', true);
			
			// Set up a handler for when the request finishes.
			xhr.onload = function () {
				if (xhr.status === 200) {
				// File(s) uploaded.
					resolve(JSON.parse(xhr.responseText));
				} else {
					alert('An error occurred!');
					reject();
				}
			};
			xhr.send(formData);
		})

		this.props.onUpload(result);

		this.input.value='';
		this.loading = false;
		this.forceUpdate();
	}

	render(){
		return (
			<div ref={e => this.div = e} onMouseOver={e=>console.log('over')} className='uploader' style={{position:'relative', lineHeight:'50px', paddingLeft:10, textAlign:'left', ...(this.props.style || {})}}>
				<style>{
					`
					.uploader{
						outline:solid 3px transparent;
						height: 50px;
					}
					.drag-file-over .uploader{
						outline:dashed 3px black;
					}
					 .uploader.hover{
						background:#e1edff;
					}
					`
				}</style>
				<input
					ref={e => this.input = e}
					style={{position:'absolute',left:0,top:0,right:0,bottom:0,display:'block',width:'100%',height:'100%', opacity:0, zIndex:9999}}
					multiple
					type='file'
					onChange={this.upload.bind(this)} 
					/>
				{this.loading && <div style={{textAlign:'center', paddingTop:'5px'}}><CircularProgress size={40} /></div>}
				{!this.loading && <span style={{fontStyle:'italic', color:'#9e9e9e'}}>Drag in files or click here to upload.</span>}
				
			</div>
		);
	}
}


class Text extends Component {

	render(){
		return (
			<TextField
				label="Title"
				margin="normal"
				{...this.props}
				value={this.props.value() || ''}
				onChange={(e)=>{ this.props.onChange(e); this.forceUpdate(); }}
			/>
		);
	}
}

function SimpleDialog(props) { 
	const Content = props.Component;
	return (
		<Dialog onClose={props.onClose} open={props.open} PaperProps={{style:{maxWidth:1900}}} >
			{props.label && <DialogTitle className="simple-dialog-title">{props.label}</DialogTitle>}
			<div>
			{!!Content && <DialogContent style={{width:props.width || '500px'}}>
				<Content {...props} />
			</DialogContent>}
			</div>
			{!!props.loading && <Wait /> }
		</Dialog>
	);
}

function FullScreenDialog(props) { 
	const Content = props.Component;
	return (
		<Dialog onClose={props.onClose} open={props.open} fullScreen TransitionComponent={Transition} >
			{props.label &&
			<DialogTitle className="simple-dialog-title">
				{props.label}
				{!props.hideClose && 
					<Close onClick={props.onClose} style={{position:'relative', top:'5px', left:'10px', cursor:'pointer' }}/>
				}
			</DialogTitle>}
			<div>
			{!!Content && <DialogContent>
				<Content {...props} />
			</DialogContent>}
			</div>
			{!!props.loading && <Wait /> }
		</Dialog>
	);
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}



const ITEM_HEIGHT = 48;

class LongMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (option) => {
	this.setState({ anchorEl: null });
	option.onClick();
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MenuIcon style={{color:'white'}} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {this.setState({ anchorEl: null });}}
          PaperProps={{
            style: {
              
              width: 200,
            },
          }}
        >
          {this.props.options.map(option => (
            <MenuItem key={option.label} onClick={() => {this.handleClose(option)}}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}


function LoginScreen({loading, onSubmit, refs, errorMessage}){
	const handleSubmit = () => {
		console.log('calling submit', refs);
		onSubmit({email:refs.email.value, password:refs.password.value});
	};
	return (
		<div
			style={{margin:'0px auto 0 auto', paddingTop:50}}
			onKeyPress={ (event) => {
				if(event.key === 'Enter' && event){
					handleSubmit();
				}
			}}
			>
			<h1 style={{fontWeight:100}}>Sign In</h1>

			<div style={{color:"#c90d4d", margin:'30px 0'}}>
				{errorMessage && <ErrorIcon style={{ width: 24, height: 24, position:'relative', top:'7px', marginRight:'10px' }} />}
				{errorMessage}
			</div>

			<TextField
				inputRef={n =>{ refs.email = n}}
				label="Username"
				margin="normal"
				fullWidth/>

			<TextField
				inputRef={n => refs.password = n}
				label="Password"
				type="password"
				margin="normal"
				fullWidth/>

			<div style={{marginTop:'20px', display:'flex', flexDirection:"row"}}>
				<div style={{flex:1}}>
					
				</div>
				<Button className='submit-button' variant="raised" color="primary" onClick={handleSubmit}>
					Login
				</Button>
			</div>
			{ !!loading && <Wait />}
		</div>
	);
}






export default App;
