import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default (props) => {
	return (
	<div className="Wait" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position:'absolute', left:0, right:0, top:0, bottom:0, background:'white', zIndex:1}}>
		<CircularProgress />
	</div>
)};

