const quotes = [
  "No guts, no story. Chris Brady",
  "My life is my message. Mahatma Gandhi",
  "Screw it, let’s do it. Richard Branson",
  "Boldness be my friend. William Shakespeare",
  "Keep going. Be all in. Bryan Hutchinson",
  "My life is my argument. Albert Schweitzer",
  "Dream big. Pray bigger.",
  "Leave no stone unturned. Euripides",
  "Fight till the last gasp. William Shakespeare",
  "Stay hungry. Stay foolish. Steve Jobs Click to tweet",
  "Broken crayons still color. Unknown Click to tweet",
  "And so the adventure begins.",
  "If you want it, work for it.",
  "You can if you think you can. George Reeves",
  "Whatever you are, be a good one. Abraham Lincoln",
  "Impossible is for the unwilling. John Keats",
  "Grow through what you go through.",
  "The wisest mind has something yet to learn. George Santanaya",
  "Take the risk or lose the chance.",
  "Do it with passion or not at all.",
  "The past does not equal the future. Tony Robbins",
  "Good things happen to those who hustle. Anaïs Nin",
  "At the end of hardship comes happiness.",
  "Don’t dream your life, live your dream.",
  "If it matters to you, you’ll find a way. Charlie Gilkey",
  "Forget about style; worry about results. Bobby Orr",
  "Whatever you do, do with all your might. Marcus Tullius Cicero",
  "Dream without fear. Love without limits.",
  "Every noble work is at first impossible. Thomas Carlyle",
  "If you’re going through hell, keep going. Winston Churchill",
  "You can do anything you set your mind to.",
  "We are twice armed if we fight with faith. Plato",
  "Be faithful to that which exists within yourself. André Gide",
  "Persistence guarantees that results are inevitable. Paramahansa Yogananda",
  "In life you need either inspiration or desperation. Tony Robbins",
  "I would rather die on my feet than live on my knees. Euripides",
  "The true success is the person who invented himself. Al Goldstein",
  "Don’t tell people your plans. Show them your results.",
  "Let him that would move the world first move himself. Socrates",
  "Go forth on your path, as it exists only through your walking. Augustine of Hippo",
  "We can do anything we want to if we stick to it long enough. Helen Keller",
  "It does not matter how slowly you go as long as you do not stop. Confucius",
  "It is better to live one day as a lion, than a thousand days as a lamb. Roman proverb",
  "Life is fragile. We’re not guaranteed a tomorrow so give it everything you’ve got. Tim Cook",
  "The two most important days in your life are the day you are born and they day you find out why. Mark Twain",
  "Fall seven times, stand up eight. Japanese proverb (This is one of my favorite short quote. Leave a reply here and let me know what’s yours!)",
  "When nothing goes right, go left. Click to tweet",
  "Life is too short to learn German. Oscar Wilde",
  "Why do they put pizza in a square box?",
  "Do crabs think we walk sideways? Bill Murray",
  "Don’t be humble, you’re not that great. Indira Gandhi",
  "I intend to live forever. So far, so good. Steven Wright",
  "My life feels like a test I didn’t study for.",
  "Why is there an expiration date on sour cream? George Carlin",
  "This suspense is terrible. I hope it will last. Oscar Wilde",
  "I drive way too fast to worry about cholesterol. Steven Wright",
  "A day without sunshine is like, you know, night. Steve Martin",
  "In heaven, all the interesting people are missing. Friedrich Nietzsche",
  "The last woman I was in was the Statue of Liberty. Woddy Allen",
  "Women want love to be a novel. Men, a short story. Daphne du Maurier",
  "Guests, like fish, begin to smell after three days. Benjamin Franklin",
  "Go to Heaven for the climate, Hell for the company. Mark Twain",
  "Whoever named it necking is a poor judge of anatomy. Groucho Marx",
  "Change is inevitable – except from a vending machine. Robert C. Gallagher",
  "I bet giraffes don’t even know what farts smell like. Bill Murray",
  "Every novel is a mystery novel if you never finish it.",
  "Why is the slowest traffic of the day called ‘rush hour’?",
  "It’s easy to quit smoking. I’ve done it hundreds of times. Mark Twain",
  "The risk I took was calculated, but man, I am bad at math.",
  "I couldn’t repair your brakes, so I made your horn louder. Steven Wright",
  "Do not read the next sentence. You little rebel, I like you.",
  "Just when I discovered the meaning of life, they changed it. George Carlin",
  "Always borrow money from a pessimist. He won’t expect it back. Oscar Wilde Click to tweet",
  "If truth is beauty, how come no one has their hair done in a library? Lily Tomlin",
  "I love deadlines. I love the whooshing noise they make as they go by. Douglas Adams",
  "I never feel more alone than when I’m trying to put sunscreen on my back. Jimmy Kimmel",
  "Those are my principles, and if you don’t like them… well, I have others. Groucho Marx",
  "The key to eating healthy is not eating any food that has a TV commercial. Mike Birbiglia",
  "I’ve got to keep breathing. It’ll be my worst business mistake if I don’t. Steve Martin",
  "There are three types of people in this world: those who can count, and those who can’t.",
  "The closest a person ever comes to perfection is when he fills out a job application form.",
  "Go! Click to tweet",
  "Love.",
  "Begin.",
  "Let go.",
  "Breathe.",
  "Slow down.",
  "Let it be.",
  "Go for it.",
  "I love you.",
  "Keep going.",
  "Choose joy.",
  "Enjoy today.",
  "C’est la vie.",
  "Choose happy.",
  "Keep it cool.",
  "Take it easy.",
  "Be in the now.",
  "Live the moment.",
  "Choose to shine.",
  "No pain, no gain.",
  "Do it. With love.",
  "Prove them wrong. Click to tweet",
  "I can and I will.",
  "It is what it is.",
  "Love conquers all.",
  "Keep your chin up.",
  "Follow your heart.",
  "Don’t rush things.",
  "You only live once.",
  "Never stop dreaming.",
  "Now is all you have.",
  "Keep moving forward.",
  "This too shall pass.",
  "Every moment matters.",
  "Love more. Worry less.",
  "Dust settles. I don’t.",
  "Nothing lasts forever.",
  "Work hard. Stay humble.",
  "Enjoy the little things.",
  "The best is yet to come.",
  "Better things are coming.",
  "Collect moments – not things.",
  "Feel the fear and do it anyway.",
  "You matter. Click to tweet",
  "You are loved.",
  "Actually, you can.",
  "Yes!! Yes!! You can do it!",
  "Focus on the good.",
  "You are doing great.",
  "We rise by lifting others.",
  "Be happy. Be bright. Be you.",
  "Every day is a second chance.",
  "You are amazing. Remember that.",
  "Darling, you are a work of art.",
  "Each day provides its own gifts. Marcus Aurelius",
  "Happiness looks gorgeous on you.",
  "You are capable of amazing things.",
  "You are somebody’s reason to smile.",
  "Try Again. Fail again. Fail better. Samuel Beckett",
  "Nothing is worth more than this day. Johann Wolfgang von Goethe",
  "Think like a proton, always positive.",
  "You are stronger than you think you are.",
  "Focus on the journey, not the destination. Greg Anderson",
  "Believe you can and you’re halfway there. Theodore Roosevelt Click to tweet",
  "Once you choose hope, anything’s possible. Christopher Reeve",
  "You make mistakes. Mistakes don’t make you.",
  "Breathe. It’s just a bad day, not a bad life.",
  "The first step is you have to say that you can. Will Smith",
  "Start every day off with a smile and get it over with. W. C. Fields",
  "If you want to lift yourself up, lift up someone else. Booker T. Washington",
  "It’s okay to not be okay as long as you are not giving up.",
  "If you feel like giving up, look back at how far you’ve come.",
  "Every day may not be good but there is something good in every day.",
  "Don’t go through life, grow through life. Eric Butterworth",
  "A problem is a chance for you to do your best. Duke Ellington",
  "You are amazing. As you are. Stronger than you know. More beautiful than you think. Tia Sparkles",
  "Everything is going to be okay in the end. If it’s not the okay, it’s not the end.",
  "No rain. No flowers. Click to tweet",
  "Shine like the stars.",
  "You make my heart smile.",
  "You will forever be my always.",
  "Your voice is my favorite sound.",
  "Throw kindness around like confetti.",
  "My favorite place is inside your hug. Click to tweet",
  "I still fall in love with you every day.",
  "You smile, I smile. That’s how it works.",
  "She was a rainbow, but he was color blind.",
  "We were born to be real, not to be perfect.",
  "When I count my blessings, I count you twice.",
  "By the way, I’m wearing the smile you gave me.",
  "If you are not too long, I will wait here for you all my life. Oscar Wilde",
  "When it rains look for rainbows. When it’s dark look for stars.",
  "To the world you may be one person, but to one person you are the world. Bill Wilson",
  "I love you not only for what you are, but for what I am when I am with you. Ray Croft",
  "You have to be odd to be number one. Dr. Seuss Click to tweet",
  "Please all, and you will please none. Aesop",
  "The fool wonders, the wise man asks. Benjamin Disraeli",
  "A smooth sea never made a skillful sailor.",
  "Pain is inevitable. Suffering is optional.",
  "A man can’t ride your back unless it’s bent. Martin Luther King Jr",
  "Don’t raise your voice. Improve your argument.",
  "Chop your own wood and it will warm you twice. Henry Ford",
  "Some people are so poor, all they have is money. Jack Kerouac",
  "All generalizations are false, including this one. Mark Twain",
  "It’s not what you look at that matters, it’s what you see. Henry David Thoreau",
  "There is no saint without a past, no sinner without a future. Augustine of Hippo Click to tweet",
  "We make a living by what we get, but we make a life by what we give. Winston Churchill",
  "It’s so strange that autumn is so beautiful; yet everything is dying.",
  "It’s no wonder that truth is stranger than fiction. Fiction has to make sense. Mark Twain",
  "The man who wants to lead the orchestra must turn his back on the crowd. James Crook",
  "What counts can’t always be counted; what can be counted doesn’t always count. Albert Einstein",
  "As a well-spent day brings happy sleep, so a life well spent brings happy death. Leonardo da Vinci",
  "Life is not about how fast you run or how high you climb, but how well you bounce. Vivian Komori",
  "Concentration is the ability to think about absolutely nothing when it is absolutely necessary. Ray Knight",
  "It is better to remain silent and be thought a fool than to open one’s mouth and remove all doubt. Mark Twain",
  "Be a voice. Not an echo. Click to tweet",
  "Seek the seeker. Ramana Maharshi",
  "No pressure, no diamonds. Thomas Carlyle",
  "Every wall is a door. Ralph Waldo Emerson",
  "The only truth is music. Jack Kerouac",
  "Silence is an answer too.",
  "Stay foolish to stay sane. Maxime Lagacé",
  "If youth knew; if age could. Sigmund Freud",
  "Time is the soul of this world. Pythagoras",
  "Stars can’t shine without darkness.",
  "He not busy being born is busy dying. Bob Dylan",
  "It takes a long time to become young. Pablo Picasso",
  "Be who you needed when you were young.",
  "Innocence is courage and clarity both. Osho",
  "Find what you love and let it kill you. Charles Bukowski",
  "Sadness flies away on the wings of time. Jean de La Fontaine",
  "I am not young enough to know everything. Oscar Wilde",
  "It is not length of life, but depth of life. Ralph Waldo Emerson",
  "Life is like the ocean, it goes up and down. Vanessa Paradis",
  "The eyes are useless when the mind is blind.",
  "You’ve gotta know what death is to know life. Jack Kevorkian",
  "Don’t let yesterday take up too much of today. Will Rogers",
  "Forgiveness is giving up hope for a better past.",
  "Be kind to unkind people, they need it the most.",
  "Solitary trees, if they grow at all, grow strong. Winston Churchill",
  "Character like a photograph, develops in darkness.",
  "The bird a nest, the spider a web, man friendship. William Blake",
  "Life is a question and how we live it is our answer. Gary Keller Click to tweet",
  "God provides the wind, but man must raise the sails. Augustine of Hippo",
  "Art is the lie that enables us to realize the truth. Pablo Picasso",
  "Enlightenment is when a wave realizes it is the ocean.",
  "The truth isn’t always beauty, but the hunger for it is. Nadine Gordimer",
  "This is your life, and it’s ending one minute at a time.",
  "Hearts are wild creatures, that’s why our ribs are cages.",
  "Life without love is like a tree without blossoms or fruit. Khalil Gibran",
  "Your faith can move mountains and your doubt can create them.",
  "The knowledge of happiness brings the knowledge of unhappiness. Swami Vivekananda",
  "There are those who give with joy, and that joy is their reward. Khalil Gibran",
  "Life is the car, your heart is the key and God is the chauffeur. Sri Sathya Sai Baba",
  "You are not a drop in the ocean. You are the entire ocean in a drop. Rumi",
  "Normality is a paved road: it’s comfortable to walk, but no flowers grow on it.",
  "One is never afraid of the unknown; one is afraid of the known coming to an end. Jiddu Krishnamurti",
  "When you’re happy you enjoy the music, when you’re sad you understand the lyrics.",
  "When you’ve seen beyond yourself, then you may find, peace of mind is waiting there. George Harrison",
  "So it goes. Kurt Vonnegut Click to tweet",
  "Live the life you’ve dreamed. Henry David Thoreau",
  "Life is not fair; get used to it. Bill Gates",
  "Life is a long lesson in humility. James M. Barrie",
  "Life is a lively process of becoming. Douglas MacArthur",
  "The unexamined life is not worth living. Socrates",
  "Life is largely a matter of expectation. Homer",
  "Don’t count the days. Make the days count.",
  "Anything worth doing is worth doing slowly. Mae West",
  "Don’t wait. Life goes faster than you think.",
  "Be soft. Do not let the world make you hard.",
  "Life is too important to be taken seriously. Oscar Wilde",
  "Life is either a daring adventure or nothing. Helen Keller",
  "The sole meaning of life is to serve humanity. Leo Tolstoy",
  "Love the life you live. Lead the life you love. Bob Marley",
  "Life is accepting what is and working from that. Gloria Naylor",
  "We are all, right now, living the life we choose. Peter McWilliams",
  "Not life, but good life, is to be chiefly valued. Socrates",
  "Live your life, do your work, then take your hat. Henry David Thoreau Click to tweet",
  "Life got to be about more than just solving problems. Elon Musk",
  "Life isn’t as serious as the mind makes it out to be. Eckhart Tolle",
  "Life is about making an impact, not making an income. Kevin Kruse",
  "The quality of life is more important than life itself. Alexis Carrel",
  "You have to die a few times before you can really live. Charles Bukowski",
  "What’s coming will come and we’ll meet it when it does. Hagrid",
  "Life shrinks or expands in proportion to one’s courage. Anaïs Nin",
  "Life is defined more by its risks than by its samenesses. Mary Anne Radmacher",
  "You only live once, but if you do it right, once is enough. Mae West",
  "Sometimes life has its way with you when you least expect it. Jon Hamm",
  "Life isn’t about getting and having, it’s about giving and being. Kevin Kruse",
  "Life can only be understood backwards; but it must be lived forwards. Soren Kierkegaard",
  "Life is amazing. Life is fucking messy. Life is what you make of it. Dwayne Johnson",
  "Life is not a problem to be solved, but a reality to be experienced. Soren Kierkegaard",
  "Nowadays people know the price of everything and the value of nothing. Oscar Wilde",
  "Only one life that soon is past, only what’s done with love will last.",
  "Don’t be afraid your life will end. Be afraid that it will never begin. Grace Hansen",
  "To live is the rarest thing in the world. Most people exist, that is all. Oscar Wilde",
  "Life is like riding a bicycle. To keep your balance, you must keep moving. Albert Einstein",
  "In three words I can sum up everything I’ve learned about life: it goes on. Robert Frost",
  "Life really does begin at forty. Up until then, you are just doing research. Carl Gustav Jung",
  "The meaning of life is to find your gift. The purpose of life is to give it away. William Skakespeare",
  "Life, it seems, is nothing if not a series of initiations, transitions, and incorporations. Alan Dundes",
  "Life becomes harder for us when we live for others, but it also becomes richer and happier. Albert Schweitzer",
  "Life is not measured by the number of breaths we take, but by the moments that take our breath away. Maya Angelou",
  "Life is pure adventure, and the sooner we realize that, the quicker we will be able to treat life as art. Maya Angelou",
  "I’ve never been poor, only broke. Being poor is a frame of mind. Being broke is only a temporary situation. Mike Todd",
  "Enjoy the little things in life because one day you’ll look back and realize they were the big things.",
  "I think people spend too much time staring into screens and not enough time drinking wine, tongue kissing, and dancing under the moon. Rachel Wolchin",
  "To love is to act. Victor Hugo Click to tweet",
  "All you need is love. John Lennon",
  "Do all things with love. Og Mandino",
  "Every lover is a soldier. Ovid",
  "Who, being loved, is poor? Oscar Wilde",
  "Speak low, if you speak love. William Shakespeare",
  "Love is the absence of judgment. Dalai Lama",
  "If you wished to be loved, love. Lucius Annaeus Seneca",
  "Love the sinner and hate the sin. Augustine of Hippo",
  "If a thing loves, it is infinite. William Blake",
  "In true love, you attain freedom. Thich Nhat Hanh",
  "Economized love is never real love. Honore De Balzac",
  "Love does not dominate; it cultivates. Johann Wolfgang von Goethe",
  "The more you judge, the less you love. Honore de Balzac",
  "Lovers quarrels are the renewal of love. Terence",
  "Life is a game and true love is a trophy. Rufus Wainwright Click to tweet",
  "To love is to receive a glimpse of heaven. Karen Sunde",
  "Love is the flower you’ve got to let grow. John Lennon",
  "If love appears, boundaries will disappear. Osho",
  "Intense love does not measure, it just gives. Mother Teresa",
  "They do not love that do not show their love. William Shakespeare",
  "Absence sharpens love, presence strengthens it. Benjamin Franklin",
  "The more you love, the more you become lovable. Osho",
  "You cannot save people, you can just love them. Anaïs Nin",
  "Love is a thing that is full of cares and fears. Ovid",
  "It is love alone that gives worth to all things. Teresa of Avila",
  "Life in abundance comes only through great love. Elbert Hubbard",
  "Love does not claim possession, but gives freedom. Rabindranath Tagore",
  "The art of love is largely the art of persistence. Albert Ellis",
  "If you judge people, you have no time to love them. Mother Teresa",
  "Never love anyone who treats you like you’re ordinary. Oscar Wilde",
  "You give me the kind of feeling people write novels about.",
  "Follow love and it will flee, flee love and it will follow. John Gay",
  "Love is like the wind, you can’t see it but you can feel it. Nicholas Sparks",
  "Stay away from people who make you feel like you’re hard to love.",
  "Perhaps one did not want to be loved so much as to be understood. George Orwell",
  "Never let an opportunity to tell someone how much they mean to you.",
  "The quickest way to get someone’s attention is to no longer want it.",
  "True love is like ghosts, which everyone talks about and few have seen. Francois de La Rochefoucauld",
  "Spread love everywhere you go. Let no one ever come to you without leaving happier. Mother Teresa",
  "Do what you love. Know your own bone; gnaw at it, bury it, unearth it, and gnaw it still. Henry David Thoreau",
  "Love needs great courage. It needs great courage because it needs the sacrifice of the ego. Osho",
  "I don’t have time to worry about who doesn’t like me. I’m too busy loving people who love me.",
  "Do not seek the because – in love there is no because, no reason, no explanation, no solutions. Anaïs Nin",
  "Whoever loves becomes humble. Those who love have, so to speak, pawned a part of their narcissism. Sigmund Freud",
  "You are enough. Click to tweet",
  "Nobody is perfect.",
  "Make yourself a priority.",
  "This is who the fuck I am. Lady Gaga",
  "Find yourself, and be that.",
  "Different doesn’t mean wrong.",
  "Where’s your will to be weird? Jim Morrison Click to tweet",
  "And now I’ll do what’s best for me.",
  "Above all things, reverence yourself. Pythagoras",
  "Self-respect knows no considerations. Mohandas Gandhi",
  "No one is laughable who laughs at himself. Lucius Annaeus Seneca",
  "Why fit in when you were born to stand out? Dr. Seuss",
  "Personal love is concentrated universal love. Maharishi Mahesh Yogi",
  "You are not designed for everyone to like you.",
  "We must be our own before we can be another’s. Ralph Waldo Emerson",
  "Accept who you are. Unless you’re a serial killer. Ellen DeGeneres",
  "Beauty begins the moment you decide to be yourself. Coco Chanel",
  "Wanting to be someone else is a waste of who you are. Kurt Cobain",
  "To love oneself is the beginning of a lifelong romance. Oscar Wilde",
  "Whatever makes you weird is probably your greatest asset.",
  "If you are lonely when you’re alone, you are in bad company. Jean-Paul Sartre",
  "The worst loneliness is not to be comfortable with yourself. Mark Twain",
  "I’m currently under construction. Thank you for your patience.",
  "The reward for conformity is that everyone likes you but yourself. Rita Mae Brown",
  "Be yourself. People don’t have to like you, and you don’t have to care.",
  "Your value doesn’t decrease based on someone’s inability to see your worth.",
  "It is better to be hated for what you are than to be loved for what you are not. André Gide",
  "I found that there is only one thing that heals every problem, and that is: to know how to love yourself. Louise Hay",
  "Smile, it’s free therapy.",
  "Freedom lies in being bold. Robert Frost",
  "A happy wife is a happy life. Gavin Rossdale",
  "Happiness lies in perspective.",
  "Boredom: the desire for desires. Leo Tolstoy",
  "Do more of what makes you happy.",
  "Happiness depends upon ourselves. Aristotle",
  "The less I needed, the better I felt. Charles Bukowski",
  "Be happy in the moment, that’s enough. Mother Teresa",
  "Happiness can exist only in acceptance. George Orwell",
  "Man is the artificer of his own happiness. Henry David Thoreau",
  "One of the keys to happiness is a bad memory. Rita Mae Brown",
  "To live happily is an inward power of the soul. Marcus Aurelius",
  "Anything you’re good at contributes to happiness. Bertrand Russell",
  "A happy soul is the best shield for a cruel world. Atticus",
  "There is no way to happiness. Happiness is the way. Thich Nhat Hanh",
  "Never expect anything. You’ll never be disappointed.",
  "Work out your own salvation. Do not depend on others. Buddha Click to tweet",
  "Happiness is the absence of the pursuit of happiness.",
  "Stay true to you and you will end up incredibly happy.",
  "Happiness is needing less. Unhappiness is wanting more.",
  "The best way to pay for a lovely moment is to enjoy it. Richard Bach",
  "If you carry joy in your heart, you can heal any moment. Carlos Santana",
  "Life’s greatest happiness is to be convinced we are loved. Victor Hugo",
  "Be happy with what you have. Be excited about what you want. Alan Cohen",
  "Find ecstasy in life; the mere sense of living is joy enough. Emily Dickinson",
  "To be content means that you realize you contain what you seek. Alan Cohen",
  "Happy are those who dare courageously to defend what they love. Ovid",
  "Whatever it is, find something to be excited about for tomorrow.",
  "Doing what you like is freedom. Liking what you do is happiness. Frank Tyger",
  "The key to happiness is doing something you like every single day. Victor Pride",
  "We don’t laugh because we’re happy – we’re happy because we laugh. William James",
  "For every minute you are angry you lose sixty seconds of happiness. Ralph Waldo Emerson",
  "Happiness is not a station you arrive at, but a manner of traveling. Margaret Lee Runbeck",
  "True happiness arises, in the first place, from the enjoyment of one’s self. Joseph Addison",
  "Base your happiness on taking action toward those goals, not on attaining them. Laura J. Tong",
  "A lifetime of happiness! No man alive could bear it: it would be hell on earth. George Bernard Shaw",
  "Most people have never learned that one of the main aims in life is to enjoy it. Samuel Butler",
  "Happiness cannot be accumulated. What is accumulated is always destructed by time. Jiddu Krishnamurti",
  "Happiness resides not in possessions, and not in gold, happiness dwells in the soul. Democritus",
  "A friend is a second self. Aristotle",
  "Choose people who lift you up. Michelle Obama",
  "Find your tribe. Love them hard.",
  "Try to be a rainbow in someone’s cloud.",
  "Love is blind; friendship closes its eyes. Friedrich Nietzsche",
  "Any day spent with you is my favorite day.",
  "The greatest gift is a portion of thyself. Ralph Waldo Emerson",
  "The only way to have a friend is to be one. Ralph Waldo Emerson",
  "Friends are the siblings God never gave us. Mencius",
  "Rare as is true love, true friendship is rarer. Jean de La Fontaine",
  "The good man is the friend of all living things. Mahatma Gandhi",
  "One loyal friend is worth ten thousand relatives. Euripides",
  "Treasure your relationships, not your possessions. Anthony J. D’Angelo Click to tweet",
  "Friendship always benefits; love sometimes injures. Seneca",
  "The language of friendship is not words but meanings. Henry David Thoreau",
  "Friends? You don’t need more than your few close ones. Alden Tan",
  "Wherever we are, it is our friends that make our world. Henry Drummond",
  "It’s a beautiful thing to love others just as they are.",
  "My best friend is the one who brings out the best in me. Henry Ford",
  "If you have one true friend you have more than your share. Thomas Fuller",
  "The real business of life is trying to understand each other. Gilbert Parker",
  "People are lonely because they build walls instead of bridges. Joseph F. Newton",
  "Friends show their love in times of trouble, not in happiness. Euripides",
  "A friend is someone who knows all about you and still loves you. Elbert Hubbard",
  "The greatest gift of life is friendship, and I have received it. Hubert H. Humphrey",
  "True friendship comes when the silence between two people is comfortable. David Tyson",
  "‘Enough’ is a feast. Buddhist proverb",
  "Count your blessings. Og Mandino",
  "Be obsessively grateful.",
  "Gratitude changes everything.",
  "Gratitude is the sign of noble souls. Aesop",
  "Start each day with a grateful heart.",
  "Joy is the simplest form of gratitude. Karl Barth",
  "Expect nothing and appreciate everything.",
  "A grateful heart is a magnet for miracles.",
  "Gratitude is riches. Complaint is poverty. Doris Day",
  "Silent gratitude isn’t very much to anyone. Gertrude Stein",
  "Don’t forget who was with you from the start.",
  "When one has a grateful heart, life is so beautiful. Roy T. Bennett",
  "Don’t cry because it’s over, smile because it happened. Ludwig Jacobowski Click to tweet",
  "Gratitude is the fairest blossom which springs from the soul. Henry Ward Beecher",
  "The essence of all beautiful art, all great art, is gratitude. Friedrich Nietzsche",
  "Pull over to the side of your journey and look how far you’ve come.",
  "How lucky I am to have something that makes saying goodbye so hard. Winnie the Pooh",
  "Gratitude is not only the greatest of virtues, but the parent of all others. Marcus Tullius Cicero",
  "Kindness is wisdom. Philip James Bailey",
  "Kindness is contagious.",
  "Fair and softly goes far. Miguel de Cervantes",
  "Kindness is always beautiful.",
  "Real kindness seeks no return.",
  "Be gentle first with yourself. Lama Yeshe",
  "Do small things with great love. Mother Teresa",
  "You will never regret being kind.",
  "In the end, only kindness matters.",
  "For it is in giving that we receive. Francis of Assisi",
  "No one has ever become poor by giving. Anne Frank",
  "Kind people are the best kind of people.",
  "Kindness is not an act, it’s a lifestyle.",
  "The more sympathy you give, the less you need. Malcolm S. Forbes",
  "In a world where you can be anything, be kind.",
  "Be kind to unkind people. They need it the most.",
  "Be kind, for everyone you meet is fighting a hard battle Ian Maclaren",
  "Be kind whenever possible. It is always possible. Dalai Lama",
  "Kindness, I’ve discovered, is everything in life. Isaac Bashevis",
  "A warm smile is the universal language of kindness. William Arthur Ward",
  "My religion is very simple. My religion is kindness. Dalai Lama",
  "Be somebody who makes everybody feel like a somebody.",
  "We are all different. Don’t judge, understand instead. Roy T. Bennett",
  "If you can’t feed a hundred people, then feed just one. Mother Teresa",
  "What wisdom can you find that is greater than kindness? Jean-Jacques Rousseau",
  "No act of kindness, no matter how small, is ever wasted. Aesop Click to tweet",
  "You can accomplish by kindness what you cannot by force. Publilius Syrus",
  "The end result of kindness is that it draws people to you. Anita Roddick",
  "Kindness is like snow – it beautifies everything it covers. Khalil Gibran",
  "We shall never know all the good that a simple smile can do. Mother Teresa",
  "It’s nice to be important, but it’s more important to be nice.",
  "How you make others feel about themselves says a lot about you.",
  "Be kinder to yourself. And then let your kindness flood the world.",
  "How do we change the world? One random act of kindness at the time.",
  "I don’t worry about people misinterpreting my kindness for weakness. Jason Bateman",
  "Wherever there is a human being, there is an opportunity for a kindness. Lucius Annaeus Seneca",
  "You are your choices. Lucius Annaeus Seneca",
  "Be true to who you are.",
  "My forte is awkwardness. Zach Galifianakis",
  "We are what we believe we are. C.S. Lewis",
  "Do as you wish. Be as you are.",
  "It’s ok to put yourself first.",
  "Know who you are and know it’s enough.",
  "The ultimate mystery is one’s own self. Sammy Davis Jr.",
  "Character is simply habit long continued. Plutarch",
  "This above all: to thine own self be true. William Shakespeare",
  "Adventure is not outside man; it is within. George Eliot",
  "You are who you are when nobody’s watching. Stephen Fry",
  "To know oneself, one should assert oneself. Albert Camus",
  "The things that we love tell us what we are. Thomas Aquinas",
  "When I am silent, I have thunder hidden inside. Rumi",
  "I may be no better, but at least I am different. Jean-Jacques Rousseau",
  "There is no competition because nobody can be me.",
  "I am not the perishable body, but the eternal Self. Ramana Maharshi",
  "What I’m looking for is not out there, it is in me. Helen Keller",
  "Being entirely honest with oneself is a good exercise. Sigmund Freud",
  "Sometimes it’s only madness that makes us what we are.",
  "As long as you can find yourself, you’ll never starve. Suzanne Collins",
  "Self-esteem is the reputation we acquire with ourselves. Nathaniel Branden",
  "Knowing others is wisdom, knowing yourself is enlightenment. Lao Tzu",
  "The only tyrant I accept in this world is the still voice within. Mohandas Gandhi Click to tweet",
  "Getting in touch with your true self must be your first priority. Tom Hopkins",
  "Life isn’t about finding yourself. Life is about creating yourself. George Bernard Shaw",
  "Never be an imitator, be always original. Don’t become a carbon copy. Osho",
  "The best way to find yourself is to lose yourself in the service of others. Mohandas Gandhi",
  "Life is really a dance if you are original, and you are meant to be original. Osho",
  "He who controls others may be powerful, but he who has mastered himself is mightier still. Lao Tzu",
  "What you get by achieving your goals is not as important as what you become by achieving your goals. Henry David Thoreau",
  "The first half of life is devoted to forming a healthy ego. The second is going inward and letting go of it. Carl Gustav Jung",
  "Dreams don’t work unless you do.",
  "Everything you can imagine is real. Pablo Picasso",
  "If you can dream it, you can do it. Walt Disney",
  "Don’t dream your life, live your dream.",
  "Nothing happens unless first we dream. Carl Sandburg",
  "The dreamers are the saviors of the world. James Allen Click to tweet",
  "If you give up on your dreams, what’s left? Jim Carrey",
  "It may be that those who do most, dream most. Stephen Butler Leacock",
  "Make your dreams happen. Die with memories, not dreams.",
  "If it’s still in your mind, it is worth taking the risk.",
  "Trust in dreams, for in them is hidden the gate to eternity. Khalil Gibran",
  "I dream. Sometimes I think that’s the only right thing to do. Haruki Murakami",
  "Dreams are often most profound when they seem the most crazy. Sigmund Freud",
  "The biggest adventure you can take is to live the life of your dreams. Oprah Winfrey",
  "The future belongs to those who believe in the beauty of their dreams. Eleanor Roosevelt",
  "You can’t put a limit on anything. The more you dream, the farther you get. Michael Phelps",
  "Go confidently in the direction of your dreams and live the life you have imagined. Henry David Thoreau",
  "You are beautiful.",
  "Be beautiful your own way.",
  "There is beauty in simplicity.",
  "To love beauty is to see light. Victor Hugo",
  "Beauty awakens the soul to act. Dante Alighieri",
  "Imperfection is beauty, madness is genius.",
  "Beauty is the promise of happiness. Stendhal",
  "A beautiful thing is never perfect. Egyptian proverb",
  "Do something wonderful, people may imitate it. Albert Schweitzer Click to tweet",
  "Everything has beauty, but not everyone sees it. Confucius",
  "Beauty always promises, but never gives anything. Simone Weil",
  "The human soul needs actual beauty more than bread. D. H. Lawrence",
  "Beauty begins the moment you decide to be yourself. Coco Chanel",
  "I’ve never seen a smiling face that was not beautiful.",
  "If the path be beautiful, let us not ask where it leads. Anatole France",
  "Beauty is not in the face; beauty is a light in the heart. Khalil Gibran",
  "Think of all the beauty still left around you and be happy. Anne Frank",
  "Sometimes you need to close your eyes to see the real beauty.",
  "For me the greatest beauty always lies in the greatest clarity. Gotthold Ephraim Lessing",
  "The truth is not always beautiful, nor beautiful words the truth. Lao Tzu",
  "I don’t think of all the misery, but of the beauty that still remains. Anne Frank",
  "You are imperfect, permanently and inevitably flawed. And you are beautiful. Amy Bloom",
  "The best and most beautiful things in the world cannot be seen or even touched – they must be felt with the heart. Helen Keller",
  "Time is money. Benjamin Franklin",
  "Time is all we have.",
  "Good things take time.",
  "Time eases all things. Sophocles",
  "Lost time is never found again. Benjamin Franklin",
  "Time brings all things to pass. Aeschylus",
  "Time is the wisest counsellor of all. Pericles",
  "Time is but the stream I go fishing in. Henry David Thoreau",
  "The trouble is, you think you have time. Jack Kornfield",
  "Nothing is so dear and precious as time. French proverb",
  "Time you enjoy wasting isn’t wasted time.",
  "Time is a game played beautifully by children. Heraclitus",
  "Don’t wait. The time will never be just right. Mark Twain",
  "Time is what we want most, but what we use worst. William Penn",
  "It’s not about having time, it’s about making time.",
  "The two most powerful warriors are patience and time. Leo Tolstoy Click to tweet",
  "In seed time learn, in harvest teach, in winter enjoy. William Blake",
  "Taking crazy things seriously is a serious waste of time. Haruki Murakami",
  "Being rich is having money, being wealthy is having time. Stephen Swid",
  "Be patient and tough; one day this pain will be useful to you. Ovid",
  "Your time is limited, so don’t waste it living someone else’s life. Steve Jobs",
  "If you love life, don’t waste time for time is what life is made up of. Bruce Lee",
  "Own less. Do more.",
  "Simplify, simplify. Henry David Thoreau",
  "Order brings peace. St-Augustine",
  "More life, less rush.",
  "Buy less, choose well. Vivienne Westwood",
  "Do more of what matters. Anthony Ongaro",
  "Fewer things. More peace.",
  "Brevity is the soul of wit. William Shakespeare",
  "Stop the glorification of busy.",
  "Simplicity is the soul of efficiency. Austin Freeman",
  "Once you need less, you will have more.",
  "I make myself rich by making my wants few. Henry David Thoreau",
  "Owning less is better than organizing more. Joshua Becker",
  "He is richest who is content with the least. Socrates",
  "Learn to say no without explaining yourself.",
  "Who is rich? He that rejoices in his portion. Benjamin Franklin",
  "Owning less is great, wanting less is better. Joshua Becker Click to tweet",
  "Someone else is happy with less than you have.",
  "You don’t need more space. You need less stuff.",
  "Minimalism is not deprivation, it’s liberation.",
  "The most important things in life aren’t things. Anthony J. D’Angelo",
  "A place for everything, everything in its place. Benjamin Franklin",
  "There is more to life than increasing its speed. Mahatma Gandhi",
  "It is quality rather than quantity that matters. Lucius Annaeus Seneca",
  "The need for less often result in a life of more. Brian Gardner",
  "Love people, use things. The opposite never works. The Minimalists",
  "The verbal expression of simplicity is ‘no thanks’.",
  "Remember, you probably already have more than you need.",
  "Owning things has a cost, and money is the least of it. David Cain",
  "Life is really simple, but we insist on making it complicated. Confucius",
  "I was living the American dream and I realized it wasn’t my dream. Joshua Fields Millburn",
  "Stop pressing rewind on things that should be deleted in your life.",
  "The more possessions you have, the less time you have to enjoy them.",
  "My goal is no longer to get more done, but rather to have less to do. Francine Jay",
  "Being a minimalist means you value yourself more than material things.",
  "Getting all of that stuff out of your life (and your mind) is addictive. Tynan",
  "Instead of working so hard to make ends meet, work on having fewer ends. Courtney Carver",
  "It’s not the daily increase but daily decrease. Hack away at the unessential. Bruce Lee",
  "Minimalism is not about having less, it’s about making room for more of what matters.",
  "Don’t buy shit you don’t need, with money you don’t have, to impress people you don’t like. Tyler Durden, Fight Club",
  "Our economy is based on spending billions to persuade people that happiness is buying things. Philip Slater",
  "You don’t need to remove clutter if you don’t let it enter your home or office in the first place. Jeri Dansky",
  "Stop trying to impress others with the things that you own. Begin inspiring them by the way you live.",
  "What is minimalism then? It’s living lightly and gracefully on the earth. It’s uncovering who you are. Francine Jay",
  "No ego, no pain. Chien-ju",
  "What we think, we become. Buddha",
  "I don’t mind what happens. Jiddu Krishnamurti",
  "He who is contented is rich. Lao Tzu",
  "Smile, breathe and go slowly. Thich Nhat Hanh",
  "Even monkeys fall from trees. Japanese proverb",
  "Confine yourself to the present. Marcus Aurelius",
  "Enough is abundance to the wise. Euripides",
  "Inhale the future. Exhale the past.",
  "He who knows he has enough is rich. Lao Tzu",
  "Wherever you are, be totally there. Eckart Tolle",
  "Water which is too pure has no fish. Ts’ai Ken T’an",
  "The essence of the Way is detachment. Bodhidharma",
  "Neither seek nor avoid, take what comes. Swami Sivananda",
  "If it comes; let it. If it goes; let it.",
  "Be like a tree. Let the dead leaves drop. Rumi Click to tweet",
  "The greatest achievement is selflessness.",
  "If all you can do is crawl, start crawling. Rumi",
  "There are no problems without consciousness. Carl Gustav Jung",
  "The quieter you become, the more you can hear. Baba Ram Dass",
  "If you are too busy to laugh, you are too busy.",
  "When the disciple is ready, the master appears. Osho",
  "Everything has beauty, but not everyone sees it. Confucius",
  "The greatest effort is not concerned with results.",
  "Give this moment your attention – and affection. Alex Blackwell",
  "Who looks outside dreams, who looks inside awakens. Carl Gustav Jung",
  "There is no path to happiness. Happiness is the path. Buddha",
  "New beginnings are often disguised as painful endings. Lao Tzu",
  "Awareness is freedom, it brings freedom, it yields freedom. Jiddu Krishnamurti",
  "The only way you can endure your pain is to let it be painful. Shunryu Suzuki",
  "Be not afraid of going slowly, be afraid only of standing still. Chinese proverb",
  "Contentment comes not so much from great wealth as from few wants. Epictetus",
  "Tension is who you think you should be, relaxation is who you are. Chinese proverb",
  "The purpose of religion is to control yourself, not criticize others. Dalai Lama",
  "I believe that the only true religion consists of having a good heart. Dalai Lama",
  "The softest things in the world overcome the hardest things in the world. Lao Tzu",
  "Do not listen with the intent to reply. But with the intent to understand.",
  "A gem cannot be polished without friction, nor a man perfected without trials. Chinese proverb",
  "You have succeeded in life when all you really want is only what you really need. Vernon Howard",
  "Open your mouth only if what you are going to say is more beautiful than silence.",
  "Life is now. There was never a time when your life was not now, nor will there ever be. Eckhart Tolle",
  "When you talk, you are only repeating what you already know. But if you listen, you may learn something new. Dalai Lama",
  "If you are depressed, you are living in the past. If you are anxious, you are living in the future. If you are at peace, you are living in the present. Lao Tzu",
  "Peace is every step. Thich Nhat Hahn",
  "My aim in life is not to judge. Jeanne Moreau",
  "A smile is the beginning of peace. Mother Teresa",
  "Nonviolence is a weapon of the strong. Mohandas Gandhi",
  "Peace is the only battle worth waging. Albert Camus",
  "Nobody can bring you peace but yourself. Ralph Waldo Emerson",
  "Freedom from desire leads to inner peace. Lao Tsu",
  "It is possible to choose peace over worry.",
  "There is no way to peace, peace is the way. A.J. Muste",
  "Peace is costly but it is worth the expense. African proverb",
  "Forgive, son; men are men; they needs must err. Euripides",
  "Peace comes from within. Do not seek it without. Buddha",
  "Let nothing and no one disturb your inner peace. Luminita Saviuc",
  "Peace and justice are two sides of the same coin. Dwight D. Eisenhower",
  "Peace of mind comes from not wanting to change others. Gerald Jampolsky",
  "Don’t hurry. Don’t worry. Do your best and let it rest.",
  "To the mind that is still, the whole universe surrenders. Lao Tzu",
  "I know but one freedom and that is the freedom of the mind. Antoine de Saint-Exupery",
  "Let loose of what you can’t control. Serenity will be yours.",
  "He is happiest, be he king or peasant, who finds peace in his home. Johann Wolfgang von Goethe",
  "Forgiveness does not change the past, but it does enlarge the future. Paul Boese",
  "We must learn to live together as brothers or perish together as fools. Martin Luther King Jr Click to tweet",
  "Peace cannot be kept by force; it can only be achieved by understanding. Albert Einstein",
  "When the power of love overcomes the love of power the world will know peace. Jimi Hendrix",
  "The ability to observe without evaluating is the highest form of intelligence. Jiddu Krishnamurti",
  "Our task must be to free ourselves by widening our circle of compassion to embrace all living creatures and the whole of nature and its beauty. Albert Einstein",
  "Time discovers truth. Lucius Annaeus Seneca",
  "Truth is a pathless land. Jiddu Krishnamurti",
  "Seek truth and you will find a path. Frank Slaughter",
  "Adversity is the first path to truth. Lord Byron",
  "Falsehood is easy, truth so difficult. George Eliot",
  "Truth is on the side of the oppressed. Malcolm X",
  "The object of the superior man is truth. Confucius",
  "The truth is rarely pure and never simple. Oscar Wilde",
  "Be truthful, nature only sides with truth. Adolf Loos",
  "There is no truth. There is only perception. Gustave Flaubert",
  "We have art in order not to die of the truth. Friedrich Nietzsche",
  "Man is the only creature that refuses to be what he is. Albert Camus",
  "Rather than love, than money, than fame, give me truth. Henry David Thoreau",
  "We search something permanent, something that will last. Jiddu Krishnamurti",
  "Seek not greatness, but seek truth and you will find both. Horace Mann Click to tweet",
  "To believe in something, and not to live it, is dishonest. Mohandas Gandhi",
  "If you tell the truth, you don’t have to remember anything. Mark Twain",
  "In a time of deceit telling the truth is a revolutionary act. George Orwell",
  "The truth is not for all men, but only for those who seek it. Ayn Rand",
  "The truth will set you free, but first it will make you miserable. James A. Garfield",
  "Do not love leisure. Waste not a minute. Be bold. Realize the Truth, here and now. Swami Sivananda",
  "Truth is always new. Truth is seeing a smile, a person, life, like if it was the first time. Jiddu Krishnamurti",
  "A man who is protecting himself constantly through knowledge is obviously not a truth-seeker. Jiddu Krishnamurti",
  "Be who you are and say what you feel, because those who mind don’t matter, and those who matter don’t mind. Bernard M. Baruch",
  "Know thyself. Socrates",
  "Wisdom begins in wonder. Socrates",
  "If you judge, investigate. Lucius Annaeus Seneca",
  "Doubt grows with knowledge. Johann Wolfgang von Goethe",
  "Everything popular is wrong. Oscar Wilde",
  "What you seek is seeking you. Rumi",
  "Observe all men, thyself most. Benjamin Franklin",
  "Doubt is the origin of wisdom. Augustine of Hippo",
  "Nature does nothing uselessly. Aristotle",
  "Listen to many, speak to a few. William Shakespeare",
  "A closed mouth catches no flies. Miguel de Cervantes",
  "Never confuse motion with action. Benjamin Franklin",
  "I’m wise because I’ve been foolish.",
  "Do what is right, not what is easy.",
  "Silence is true wisdom’s best reply. Euripides",
  "Wisdom comes only through suffering. Aeschylus",
  "A loving heart is the truest wisdom. Charles Dickens",
  "An overflow of good converts to bad. William Shakespeare",
  "To find yourself, think for yourself. Socrates",
  "My life is perfect even when it’s not. Ellen DeGeneres",
  "In teaching others we teach ourselves.",
  "Maturity comes with experience, not age.",
  "Pain is inevitable. Suffering is optional. Haruki Murakami",
  "It is better to travel well than to arrive. Buddha",
  "What’s meant to be will always find a way.",
  "Love is too young to know what conscience is. William Shakespeare",
  "Wisely, and slow. They stumble that run fast. William Shakespeare Click to tweet",
  "With wisdom, comes the desire for simplicity.",
  "Wise is he who collects the wisdom of others. Juan Guerra Caceras",
  "Nothing has more strength than dire necessity. Euripides",
  "He who would travel happily must travel light. Antoine de Saint-Exupery",
  "When knowledge becomes rigid, it stops living. Anselm Kiefer",
  "Wealth is the ability to fully experience life. Henry David Thoreau",
  "Memory is the old, and it is afraid of the new. Osho",
  "Nothing external to you has any power over you. Ralph Waldo Emerson",
  "The most certain sign of wisdom is cheerfulness. Michel de Montaigne",
  "Not being able to govern events, I govern myself. Michel de Montaigne",
  "The mind is everything. What you think you become. Buddha",
  "Happy is the hearing man; unhappy the speaking man. Ralph Waldo Emerson",
  "Honesty is the first chapter in the book of wisdom. Thomas Jefferson",
  "Who looks outside, dreams; who looks inside, awakes. Carl Gustav Jung",
  "Most powerful is he who has himself in his own power. Lucius Annaeus Seneca",
  "I don’t like that man. I must get to know him better. Abraham Lincoln",
  "Complete abstinence is easier than perfect moderation. Augustine of Hippo",
  "When you give yourself, you receive more than you give. Antoine de Saint-Exupery",
  "If you think you know the answer, you’re far from wise. Trent Hamm",
  "Judge a man by his questions rather than by his answers. Voltaire",
  "We all make choices, but in the end our choices make us.",
  "I am not what happened to me, I am what I choose to become. Carl Gustav Jung",
  "He who knows that enough is enough will always have enough. Lao Tzu",
  "He who knows, does not speak. He who speaks, does not know. Lao Tzu",
  "The wisest man is generally he who thinks himself the least so. Nicolas Baileau",
  "Never let the things you want make you forget the things you have.",
  "Nothing ever goes away until it has taught us what we need to know.",
  "As far as I’m concerned, I prefer silent vice to ostentatious virtue. Albert Einstein",
  "To become a spectator of one’s own life is to escape the suffering of life. Oscar Wilde",
  "A fool thinks himself to be wise, but a wise man knows himself to be a fool. William Shakespeare",
  "Insanity is doing the same thing, over and over again, but expecting different results. Albert Einstein",
  "Whenever you find yourself on the side of the majority, it is time to pause and reflect. Mark Twain",
  "Courage, dear heart. C.S. Lewis",
  "He who is brave is free. Lucius Annaeus Seneca",
  "From caring comes courage. Lao Tzu",
  "Don’t stop until you’re proud.",
  "Courage doesn’t always roar. Mary Anne Radmacher",
  "What keeps me going is goals. Muhammad Ali",
  "Life is tough but so you are.",
  "Fortune and love favor the brave. Ovid",
  "I’m strong because I’ve been weak.",
  "The best way out is always through. Robert Frost",
  "There is no education like adversity. Benjamin Disraeli",
  "One man with courage makes a majority. Andrew Jackson",
  "I’m fearless because I’ve been afraid.",
  "The pain you feel now, will be a strength after some time.",
  "Diligence is the mother of good fortune. Benjamin Disraeli",
  "Because of a great love, one is courageous. Lao Tzu",
  "Tough times never last, but tough people do.",
  "Great deeds are usually wrought at great risks. Herodotus",
  "He that can have patience can have what he will. Benjamin Franklin",
  "Rock bottom has built more heroes than privilege.",
  "I’ve never met a strong person with an easy past.",
  "Things that were hard to bear are sweet to remember. Lucius Annaeus Seneca",
  "Courage is like a muscle; it is strengthened by use. Ruth Gordon",
  "It doesn’t matter how slow you go as long as you don’t stop. Confucius Click to tweet",
  "Rock bottom became the solid foundation on which I rebuilt my life. J. K. Rowling",
  "The weak can never forgive. Forgiveness is the attribute of the strong. Mahatma Gandhi",
  "Be faithful in small things because it is in them that your strength lies. Mother Teresa",
  "The world breaks everyone, and afterwards many are strong at the broken places. Ernest Hemingway",
  "People cry, not because they’re weak. It’s because they’ve been strong for too long. Johnny Depp",
  "Life is very interesting. In the end, some of your greatest pains become your greatest strengths. Drew Barrymore",
  "Persevere and get it done. George Allen Sr",
  "Never, never, never give up. Winston Churchill",
  "Much effort, much prosperity. Euripides",
  "Slow and steady wins the race.",
  "How long should you try? Until. Jim Rohn",
  "If you don’t ask, you don’t get. Stevie Wonder",
  "Nothing worth having comes easy.",
  "I will not walk backward in life. J. R. R. Tolkien",
  "It’s pain that changes our lives. Steve Martin",
  "Our whole life is solving puzzles. Erno Rubik",
  "The struggle is part of the story.",
  "Persevere in virtue and diligence. Plautus",
  "Life is short and progress is slow. Gabriel Lippmann",
  "No great thing is created suddenly. Epictetus",
  "God helps those who help themselves. Benjamin Franklin",
  "Patience is the companion of wisdom. Augustine of Hippo",
  "Perseverance, secret of all triumphs. Victor Hugo",
  "Victory belongs to the most persevering. Napoleon Bonaparte",
  "I’m a slow walker, but I never walk back. Abraham Lincoln",
  "In the middle of difficulty lies opportunity. Albert Einstein",
  "Never give up on the things that make you smile.",
  "He that can have patience can have what he will. Benjamin Franklin",
  "A lost battle is a battle one thinks one has lost. Jean-Paul Sartre",
  "Perseverance is a virtue that cannot be understated. Bob Riley",
  "The journey of a thousand miles begins with one step. Lao Tzu",
  "You may see me struggle, but you will never see me quit.",
  "Perseverance is failing 19 times and succeeding the 20th. Julie Andrews",
  "Thankfully, persistence is a great substitute for talent. Steve Martin",
  "You get credit for what you finished, not what you started.",
  "I was taught the way of progress is neither swift nor easy. Marie Curie",
  "Persistence is to the character of man as carbon is to steel. Napoleon Hill",
  "A man will fight harder for his interests than for his rights. Napoleon Bonaparte",
  "Anyone who has ever made anything of importance was disciplined. Andrew Hendrixson",
  "Character consists of what you do on the third and fourth tries. James A. Michener",
  "Nothing is permanent in this wicked world, not even our troubles. Charlie Chaplin",
  "No matter how you feel, get up, dress up, show up and never give up.",
  "Don’t quit. Suffer now and live the rest of your life as a champion. Muhammad Ali",
  "Difficult things take a long time, impossible things a little longer.",
  "It’s not that I’m so smart, it’s just that I stay with problems longer. Albert Einstein Click to tweet",
  "A river cuts through a rock not because of its power but its persistence.",
  "The will to persevere is often the difference between failure and success. David Sarnoff",
  "Ambition is the path to success. Persistence is the vehicle you arrive in. Bill Bradley",
  "Perseverance is not a long race; it is many short races one after the other. Walter Elliot",
  "Patience, persistence and perspiration make an unbeatable combination for success. Napoleon Hill",
  "Through perseverance many people win success out of what seemed destined to be certain failure. Benjamin Disraeli",
  "Perseverance is the hard work you do after you get tired of doing the hard work you already did. Newt Gingrich",
  "It is the cheerful mind that is persevering. It is the strong mind that hews its way through a thousand difficulties. Swami Sivananda",
  "Belief creates the actual fact. William James",
  "Believing requires action. James E. Faust",
  "Prayer is man’s greatest power. W. Clement Stone",
  "To me faith means not worrying. John Dewey",
  "Set goals. Say prayers. Work hard.",
  "Faith is spiritualized imagination. Henry Ward Beecher",
  "Empty yourself and let God fill you.",
  "God gives where he finds empty hands. Augustine of Hippo",
  "Faith is the quiet cousin of courage. Judith Hanson Lasater",
  "Faith makes things possible, not easy. Click to tweet",
  "In art as in love, instinct is enough. Anatole France",
  "A man of courage is also full of faith. Marcus Tullius Cicero",
  "Faith: not wanting to know what is true. Friedrich Nietzsche",
  "Unless you believe, you will not understand. Augustine of Hippo",
  "I believe life takes us where we need to be. Sasha Alexander",
  "The Lord is greater than the giants you face. John 4.9",
  "If you’re praying about it. God is working on it.",
  "Live your beliefs and you can turn the world around. Henry David Thoreau",
  "Prayer is to be in love, to be in love with the whole. Osho",
  "Do not pray for an easier life, pray to be a stronger man. John F. Kennedy",
  "Faith is not something to grasp, it is a state to grow into. Mahatma Gandhi",
  "Prayer is the key of the morning and the bolt of the evening. Mahatma Gandhi",
  "When someone shows you who they are, believe them the first time. Maya Angelou",
  "Let life happen to you. Believe me: life is in the right, always. Rainer Maria Rilke",
  "Faith is believing in something when common sense tells you not to.",
  "We must accept finite disappointment, but never lose infinite hope. Martin Luther King Jr",
  "Faith is taking the first step even when you don’t see the whole staircase. Martin Luther King Jr",
  "Pursue some path, however narrow and crooked, in which you can walk with love and reverence. Henry David Thoreau",
  "You can totally do this.",
  "Humor comes from self-confidence. Rita Mae Brown",
  "I think you should just go for it.",
  "We are always the same age inside. Gertrude Stein",
  "Trust is the greatest intelligence. Osho",
  "Confidence is one of the sexiest things. Katherine Jenkins",
  "You have to laugh, especially at yourself. Madonna",
  "Confidence is a stain they can’t wipe off. Lil Wayne",
  "All anything takes, really, is confidence. Rachel Ward",
  "Coffee in one hand. Confidence in the other.",
  "Confidence comes from discipline and training. Robert Kiyosaki",
  "A certain death of an artist is overconfidence. Robin Trower",
  "Your energy introduces you before you even speak.",
  "Act as if what you do makes a difference. It does. William James",
  "Confidence is contagious. So is lack of confidence. Vince Lombardi",
  "As is our confidence, so is our capacity. William Hazlitt",
  "Just trust yourself, then you will know how to live. Johann Wolfgang von Goethe",
  "The most beautiful thing you can wear is confidence. Blake Lively",
  "Talk to yourself like you would to someone you love. Brene Brown",
  "Courage is a mean with regard to fear and confidence. Aristotle",
  "A tiger doesn’t lose sleep over the opinion of sheep.",
  "Stop doubting yourself, work hard and make it happen.",
  "With confidence, you have won before you have started. Marcus Garvey",
  "No one can make you feel inferior without your consent. Eleanor Roosevelt",
  "Confidence cannot find a place wherein to rest in safety. Virgil",
  "As soon as you trust yourself, you will know how to live. Johann Wolfgang von Goethe",
  "Confidence is the most important single factor in this game. Jack Nicklaus",
  "Self-confidence is the first requisite to great undertakings. Samuel Johnson Click to tweet",
  "All you need is ignorance and confidence and the success is sure. Mark Twain",
  "Concentration comes out of a combination of confidence and hunger. Arnold Palmer",
  "I’m always impressed by confidence, kindness and a sense of humor. Tamara Mellon",
  "Nothing builds self-esteem and self-confidence like accomplishment. Thomas Carlyle",
  "The hardest step she ever took was to blindly trust in who she was. Atticus",
  "Inaction breeds doubt and fear. Action breeds confidence and courage. Dale Carnegie",
  "The circulation of confidence is better than the circulation of money. James Madison",
  "I have great faith in fools – self-confidence my friends will call it. Edgar Allan Poe",
  "Too many people overvalue what they are not and undervalue what they are. Malcolm S. Forbes",
  "Confidence in the goodness of another is good proof of one’s own goodness. Michel de Montaigne",
  "Never bend your head. Always hold it high. Look the world straight in the face. Helen Keller",
  "Confidence is not ‘they will like me’. Confidence is ‘I’ll be fine if they don’t’.",
  "I do not believe in taking the right decision, I take a decision and make it right. Muhammad Ali Jinnah",
  "The more you know who you are and what you want, the less you let things upset you.",
  "My confidence comes from the daily grind – training my butt off day in and day out. Hope Solo",
  "A ‘No’ uttered from the deepest conviction is better than a ‘Yes’ merely uttered to please, or worse, to avoid trouble. Mahatma Gandhi",
  "Go wild, for a while.",
  "Creativity takes courage. Henri Matisse",
  "An artist is an explorer. Henri Matisse",
  "Imagination rules the world. Napoleon Bonaparte",
  "Be anything but predictable.",
  "Creativity is not a competition.",
  "Zeal will do more than knowledge. William Hazlitt Click to tweet",
  "Pure logic is the ruin of the spirit. Antoine de Saint-Exupery",
  "Without freedom, there is no creation. Jiddu Krishnamurti",
  "Creativity comes from a conflict of ideas. Donatella Versace",
  "The man who has no imagination has no wings. Muhammad Ali",
  "Be yourself; everyone else is already taken. Oscar Wilde",
  "The worst enemy to creativity is self-doubt. Sylvia Plath",
  "The chief enemy of creativity is good sense. Pablo Picasso",
  "You were born an original, don’t die a copy.",
  "I like nonsense, it wakes up the brain cells. Dr. Seuss",
  "Name the greatest of all inventors. Accident. Mark Twain",
  "Originality is nothing but judicious imitation. Voltaire",
  "Creativity is the greatest rebellion in existence. Osho",
  "Consistency is the last refuge of the unimaginative. Oscar Wilde",
  "I begin with an idea, and then it becomes something else. Pablo Picasso",
  "Creativity requires the courage to let go of certainties. Erich Fromm",
  "Creativity – like human life itself – begins in darkness. Julia Cameron",
  "In order to be irreplaceable one must always be different. Coco Chanel",
  "Mystery is at the heart of creativity. That, and surprise. Julia Cameron",
  "No great mind has ever existed without a touch of madness. Aristotle",
  "A work of art is the unique result of a unique temperament. Oscar Wilde",
  "The inner fire is the most important thing mankind possesses. Edith Södergran",
  "There is no innovation and creativity without failure. Period. Brene Brown",
  "To live a creative life, we must lose our fear of being wrong. Joseph Chilton Pearce",
  "A man may die, nations may rise and fall, but an idea lives on. John F. Kennedy",
  "The true sign of intelligence is not knowledge but imagination. Albert Einstein",
  "If you want something new, you have to stop doing something old. Peter F. Drucker",
  "Learn the rules like a pro, so you can break them like an artist. Pablo Picasso",
  "It is better to fail in originality than to succeed in imitation. Herman Melville",
  "Fashion’s not about looking back. It’s always about looking forward. Anna Wintour",
  "The monotony and solitude of a quiet life stimulates the creative mind. Albert Einstein",
  "When you do things from your soul, you feel a river moving in you, a joy. Rumi",
  "I never made one of my discoveries through the process of rational thinking. Albert Einstein",
  "If you want something you never had, you have to do something you’ve never done.",
  "Creativity is allowing yourself to make mistakes. Art is knowing which ones to keep. Scott Adams",
  "The doer alone learneth. Friedrich Nietzsche",
  "Be curious. Not judgmental. Walt Whitman",
  "Sometimes you win, sometimes you learn. John Maxwell Click to tweet",
  "They know enough who know how to learn. Henry Adams",
  "Life is trying things to see if they work.",
  "The only source of knowledge is experience. Albert Einstein",
  "Any fool can know. The point is to understand. Albert Einstein",
  "I never learned from a man who agreed with me. Robert A. Heinlein",
  "Change is the end result of all true learning. Leo Buscaglia",
  "The important thing is not to stop questioning. Albert Einstein",
  "The wise learns many things from their enemies. Aristophanes",
  "As long as you live, keep learning how to live. Lucius Annaeus Seneca",
  "The noblest pleasure is the joy of understanding. Leonardo da Vinci",
  "Blessed are the curious for they shall have adventures.",
  "The most necessary learning is that which unlearns evil. Antisthenes",
  "I have no special talent. I am only passionately curious. Albert Einstein",
  "I am learning all the time. The tombstone will be my diploma. Eartha Kitt",
  "Your most unhappy customers are your greatest source of learning. Bill Gates",
  "Real learning comes about when the competitive spirit has ceased. Jiddu Krishnamurti",
  "It is only when we forget all our learning that we begin to know. Henry David Thoreau",
  "I like to listen. I have learned a great deal from listening carefully. Most people never listen. Ernest Hemingway",
  "Education is soul crafting. Cornel West",
  "Love is a better teacher than duty. Albert Einstein",
  "Nine tenths of education is encouragement. Anatole France",
  "Those who know how to think need no teachers. Mohandas Gandhi",
  "It is a wise father that knows his own child. William Shakespeare",
  "The highest result of education is tolerance. Helen Keller",
  "Teaching is the highest form of understanding. Aristotle",
  "A book is a dream that you hold in your hands. Neil Gaiman",
  "People only see what they are prepared to see. Ralph Waldo Emerson",
  "Nothing ever becomes real till it is experienced. John Keats",
  "A student of life considers the world a classroom. Harvey Mackay",
  "An investment in knowledge pays the best interest. Benjamin Franklin",
  "Don’t let schooling interfere with your education. Mark Twain",
  "Children have more need of models than of critics. Carolyn Coats",
  "Education is the ability to meet life’s situations. Dr. John G. Hibben",
  "If you think education is expensive, try ignorance. Andy McIntyre",
  "The great aim of education is not knowledge but action. Herbert Spencer Click to tweet",
  "A child educated only at school is an uneducated child. George Santayana",
  "Teachers open the door, but you must enter by yourself. Chinese proverb",
  "Be brave. Take risks. Nothing can substitute experience. Paulo Coelho",
  "Experience, travel. These are an education in themselves. Euripides",
  "The roots of education are bitter, but the fruit is sweet. Aristotle",
  "There is no greater education than one that is self-driven. Neil deGrasse Tyson",
  "The mind unlearns with difficulty what it has long learned. Lucius Annaeus Seneca",
  "Education is not filling a pail but the lighting of a fire. William Butler Yeats",
  "I cannot teach anybody anything, I can only make them think. Socrates",
  "In this life, all you need is for someone to believe in you. J. R. Martinez",
  "Intelligence plus character – that is the goal of true education. Martin Luther King Jr",
  "If you want happiness for a lifetime, help the next generation. Chinese saying",
  "The mind is not a vessel to be filled, but a fire to be kindled. Plutarch",
  "Education is not preparation for life; education is life itself. John Dewey",
  "Educate the children and it won’t be necessary to punish the men. Pythagoras",
  "The world is a book and those who do not travel read only one page. Augustine of Hippo",
  "It’s not that hard to stay grounded. It’s the way I was brought up. Sidney Crosby",
  "Educating the mind without educating the heart is no education at all. Aristotle",
  "Education is the most powerful weapon which you can use to change the world. Nelson Mandela",
  "To be successful in life what you need is education, not literacy and degrees. Munshi Premchand",
  "Education is what remains after one has forgotten what one has learned in school. Albert Einstein",
  "Do not go where the path may lead, go instead where there is no path and leave a trail. Ralph Waldo Emerson",
  "If you want your children to turn out well, spend twice as much time with them, and half as much money. Abigail Van Buren",
  "Work without love is slavery. Mother Teresa",
  "Don’t be busy, be productive.",
  "Forget shortcuts, work for it.",
  "Work to become, not to acquire.",
  "Well done is better than well said.",
  "Go the extra mile – it’s never crowded.",
  "You have to fight to reach your dream. Lionel Messi",
  "Do your duty today and repent tomorrow. Mark Twain",
  "What is once well done is done forever. Henry David Thoreau",
  "Don’t mistake activity with achievement. John Wooden",
  "All happiness depends on courage and work. Honore de Balzac",
  "You are not your resume, you are your work. Seth Godin",
  "Without hard work, nothing grows but weeds. Gordon B. Hinckley",
  "Let the beauty of what you love be what you do. Rumi",
  "Pleasure in the job puts perfection in the work. Aristotle",
  "I learned the value of hard work by working hard. Margaret Mead",
  "Work hard in silence. Let success make the noise.",
  "Work hard, be kind, and amazing things will happen. Conan O’Brien Click to tweet",
  "Work until you no longer have to introduce yourself.",
  "As a cure for worrying, work is better than whiskey. Ralph Waldo Emerson",
  "The beginning is the most important part of the work. Plato",
  "The reward of one duty is the power to fulfill another. George Eliot",
  "Once you do something you love, you never have to work again. Willie Hill",
  "Nearly everything you do is of no importance, but it is important that you do it. Mohandas Gandhi",
  "Do not hire a man who does your work for money, but him who does it for love of it. Henry David Thoreau",
  "Without work, all life goes rotten. But when work is soulless, life stifles and dies. Albert Camus",
  "Never work just for money or power. They won’t save your soul or help you sleep at night. Marian Wright Edelman",
  "Leadership is influence. John C. Maxwell",
  "One must steer, not talk. Lucius Annaeus Seneca",
  "We rise by lifting others.",
  "A leader is a dealer in hope. Napoleon Bonaparte",
  "To lead people walk behind them. Lao Tzu",
  "A good example is the best sermon. Benjamin Franklin",
  "Anyone can hold the helm when the sea is calm. Publilius Syrus",
  "The speed of the leader is the speed of the gang. Mary Kay Ash",
  "A ruler should be slow to punish and swift to reward. Ovid",
  "He who is not a good servant will not be a good master. Plato",
  "Each person must live their life as a model for others. Rosa Parks",
  "Leaders don’t create followers, they create more leaders. Tom Peters",
  "The strength of the group is the strength of the leaders. Vince Lombardi",
  "You don’t have to hold a position in order to be a leader. Henry Ford",
  "Build your reputation by helping other people build theirs. Anthony J. D’Angelo",
  "Leadership is the capacity to translate vision into reality. Warren Bennis",
  "When you can’t make them see the light, make them feel the heat. Ronald Reagan Click to tweet",
  "Keep your fears to yourself, but share your courage with others. Robert Louis Stevenson",
  "To handle yourself, use your head; to handle others, use your heart. Eleanor Roosevelt",
  "To be a leader, bring certainty, to an environment where there isn’t any. Tony Robbins",
  "Example is not the main thing in influencing others. It is the only thing. Albert Schweitzer",
  "A genuine leader is not a searcher for consensus but a molder of consensus. Martin Luther King Jr",
  "May you live your life as if the maxim of your actions were to become universal law. Immanuel Kant",
  "If your actions inspire others to dream more, learn more, do more and become more, you are a leader.",
  "Attitude is everything. Charles Swindoll",
  "Be groovy or leave, man. Bob Dylan",
  "A true man hates no one. Napoleon Bonaparte",
  "What you are comes to you. Ralph Waldo Emerson",
  "Who sows virtue reaps honor. Leonardo da Vinci",
  "Don’t find fault, find a remedy. Henry Ford",
  "A great mind becomes a great fortune. Lucius Annaeus Seneca",
  "Humor is mankind’s greatest blessing. Mark Twain",
  "The greatest remedy for anger is delay. Lucius Annaeus Seneca",
  "Love all, trust a few, do wrong to none. William Shakespeare",
  "Showing off is the fool’s idea of glory. Bruce Lee",
  "Let’s not be narrow, nasty, and negative. T. S. Eliot",
  "Be someone who you would want to be around. Craig Jarrow",
  "Energy and persistence conquer all things. Benjamin Franklin",
  "A great man is always willing to be little. Ralph Waldo Emerson Click to tweet",
  "Ethics is nothing else than reverence for life. Albert Schweitzer",
  "Humility is the solid foundation of all virtues. Confucius",
  "Goodness is the only investment that never fails. Henry David Thoreau",
  "Be true to your work, your word, and your friend. Henry David Thoreau",
  "A quick temper will make a fool of you soon enough. Bruce Lee",
  "Nothing great was ever achieved without enthusiasm. Ralph Waldo Emerson",
  "Knowledge will give you power, but character respect. Bruce Lee",
  "Strive not to be a success, but rather to be of value. Albert Einstein",
  "Swallow your pride, you will not die, it’s not poison. Bob Dylan",
  "Attention is the rarest and purest form of generosity.",
  "Attitude is a little thing that makes a big difference. Winston Churchill",
  "Don’t talk, act. Don’t say, show. Don’t promise, prove.",
  "Remind yourself that your task is to be a good human being. Marcus Aurelius",
  "Be careless in your dress if you will, but keep a tidy soul. Mark Twain",
  "Let us train our minds to desire what the situation demands. Lucius Annaeus Seneca",
  "Really great people make you feel that you, too, can become great. Mark Twain",
  "Waste no more time arguing about what a good man should be. Be one. Marcus Aurelius",
  "Being both soft and strong is a combination very few have mastered.",
  "The firm, the enduring, the simple, and the modest are near to virtue. Confucius",
  "Life is good when we think it’s good. Life is bad when we don’t think. Douglas Horton",
  "Drop all your scientific attitudes. Become a little more fluid, more melting, more merging. Osho",
  "Instead of asking ‘what do I want from life?’, a more powerful question is, ‘what does life want from me?’. Eckhart Tolle",
  "To hold a pen is to be at war. Voltaire",
  "Easy reading is damn hard writing. Nathaniel Hawthorne",
  "Write what should not be forgotten. Isabel Allende",
  "The first draft of anything is shit. Ernest Hemingway",
  "An author, behind his words, is naked. Terri Guillemets",
  "Tears are words that need to be written. Paulo Coelho",
  "If it sounds like writing, I rewrite it. Elmore Leonard",
  "Every writer I know has trouble writing. Joseph Heller",
  "The wastebasket is a writer’s best friend. Isaac Bashevis Singer",
  "A word after a word after a word is power. Margaret Atwood",
  "If I don’t write to empty my mind, I go mad. Lord Byron",
  "The best style is the style you don’t notice. Somerset Maugham",
  "Fill your paper with the breathings of your heart. William Wordsworth",
  "The scariest moment is always just before you start. Stephen King",
  "A professional writer is an amateur who didn’t quit. Richard Bach",
  "You must stay drunk on writing so reality cannot destroy you. Ray Bradbury",
  "One day I will find the right words, and they will be simple. Jack Kerouac Click to tweet",
  "We write to taste life twice, in the moment and in retrospect. Anaïs Nin",
  "The art of writing is the art of discovering what you believe. Gustave Flaubert",
  "There is no greater agony than bearing an untold story inside you. Maya Angelou",
  "There is no real ending. It’s just the place where you stop the story. Frank Herbert",
  "Writing is an exploration. You start from nothing and learn as you go. E. L. Doctorow",
  "The purpose of a writer is to keep civilization from destroying itself. Albert Camus",
  "The most beautiful things are those that madness prompts and reason writes. André Gide",
  "There is nothing to writing. All you do is sit down at a typewriter and bleed. Ernest Hemingway",
  "Write with conviction. Pick a side and be bold. And if you’re wrong, admit it. Jeff Goins",
  "Imagination is like a muscle. I found out that the more I wrote, the bigger it got. Philip Jose Farmer",
  "We have to continually be jumping off cliffs and developing our wings on the way down. Kurt Vonnegut",
  "All you have to do is write one true sentence. Write the truest sentence that you know. Ernest Hemingway",
  "Better to write for yourself and have no public, than to write for the public and have no self. Cyril Connolly",
  "Change before you have to. Jack Welch",
  "To say goodbye is to die a little. Raymond Chandler",
  "There is nothing so stable as change. Bob Dylan",
  "It is in changing that we find purpose. Heraclitus",
  "Every day is a chance to change your life.",
  "In a gentle way, you can shake the world. Mahatma Gandhi",
  "Our only security is our ability to change. John Lilly",
  "Change your thoughts and you change your world. Norman Vincent Peale",
  "Intelligence is the ability to adapt to change. Stephen Hawking",
  "No map is possible because life goes on changing. Osho",
  "If it doesn’t challenge you, it won’t change you.",
  "It is never too late to be what you might have been. George Eliot",
  "Change is not merely necessary to life – it is life. Alvin Toffler",
  "If you want to make enemies, try to change something. Woodrow Wilson",
  "Failure is not fatal, but failure to change might be. John Wooden",
  "You affect your subconscious mind by verbal repetition. W. Clement Stone",
  "When you blame others, you give up your power to change. Robert Anthony",
  "It is not necessary to change. Survival is not mandatory. W. Edwards Deming Click to tweet",
  "Prayer does not change God, but it changes him who prays. Soren Kierkegaard",
  "To improve is to change; to be perfect is to change often. Winston Churchill",
  "To change one’s life: Start immediately. Do it flamboyantly. William James",
  "Only the new, accepted deeply and totally, can transform you. Osho",
  "No matter what people tell you, words and ideas can change the world. Robin Williams",
  "Life belongs to the living, and he who lives must be prepared for changes. Johann Wolfgang von Goethe",
  "Let your smile change the world, but don’t let the world change your smile.",
  "The world hates change, yet it is the only thing that has brought progress. Charles Kettering",
  "To change the world I have to change myself, break away from my conditioning. Jiddu Krishnamurti",
  "Everyone thinks of changing the world, but no one thinks of changing himself. Leo Tolstoy",
  "You can’t start the next chapter of your life if you keep re-reading the last one.",
  "The ones who are crazy enough to think they can change the world are the ones who do. Steve Jobs",
  "The secret of change is to focus all of your energy, not on fighting the old, but on building the new. Socrates",
  "Much effort, much prosperity. Euripides",
  "Make your life a masterpiece. Brian Tracy",
  "Success is the child of audacity. Benjamin Disraeli",
  "The best revenge is massive success. Franck Sinatra",
  "A goal without a plan is just a wish. Antoine de Saint-Exupery",
  "We aim above the mark to hit the mark. Ralph Waldo Emerson",
  "I never dream of success. I worked for it. Estee Lauder",
  "The harder the battle the sweeter the victory. Bob Marley",
  "Action is the foundational key to all success. Pablo Picasso",
  "The secret of success is constancy of purpose. Benjamin Disraeli",
  "What you think today is what you live tomorrow. Remez Sasson",
  "Success is where preparation and opportunity meet. Bobby Unser",
  "Be content to act, and leave the talking to others. Baltasar",
  "Success is how high you bounce when you hit bottom. George S. Patton",
  "No one knows what to say in the loser’s locker room. Muhammad Ali",
  "If you have no critics you’ll likely have no success. Malcolm X",
  "Everybody pities the weak; jealousy you have to earn. Arnold Schwarzenegger",
  "The measure of success is happiness and peace of mind. Bobby Davro",
  "I couldn’t wait for success, so I went ahead without it. Jonathan Winters",
  "Start where you are. Use what you have. Do what you can. Arthur Ashe",
  "Before anything else, preparation is the key to success. Alexander Graham Bell",
  "Success is the accumulation of small advantages over time. Aaron Lynn",
  "The path to success is to take massive, determined action. Tony Robbins",
  "The secret of my success is a two word answer: Know people. Harvey S. Firestone",
  "Don’t let what you cannot do interfere with what you can do. John R. Wooden",
  "I find that the harder I work, the more luck I seem to have. Thomas Jefferson Click to tweet",
  "It’s not whether you get knocked down, it’s whether you get up. Vince Lombardi",
  "Judge your success by what you had to give up in order to get it. Dalai Lama",
  "The successful warrior is the average man, with laser-like focus. Bruce Lee",
  "I attribute my success to this – I never gave or took any excuse. Florence Nightingale",
  "My success isn’t a result of arrogance – it’s a result of belief. Conor McGregor",
  "Success is the sum of small efforts, repeated day in and day out. Robert Collier",
  "I always wanted to be the best and to get the most out of myself. Sidney Crosby",
  "Success is only meaningful and enjoyable if it feels like your own. Michelle Obama",
  "Success is largely a matter of holding on after others have let go. William Feather",
  "Success usually comes to those who are too busy to be looking for it. Henry David Thoreau",
  "I want to be the best, so whatever comes with that, I’ll have to accept. Sidney Crosby",
  "Success is nothing more than a few simple disciplines, practiced every day. Jim Rohn",
  "I’ve failed over and over and over again in my life and that is why I succeed. Michael Jordan",
  "Success is the ability to go from failure to failure without losing your enthusiasm. Winston Churchill",
  "Fear and time are inseparable. Jiddu Krishnamurti",
  "Fear is stupid. So are regrets. Marilyn Monroe",
  "Where fear is, happiness is not. Lucius Annaeus Seneca",
  "Take every chance. Drop every fear.",
  "Always do what you are afraid to do. Ralph Waldo Emerson",
  "Fear makes the wolf bigger than he is. German proverb",
  "Fear is the voice of the rational mind.",
  "Fear is destructive; love is a creative energy. Osho",
  "If it scares you, it might be a good thing to try. Seth Godin",
  "Limits, like fear, is often an illusion. Michael Jordan",
  "Fear tricks us into living a boring life. Donald Miller",
  "To conquer fear is the beginning of wisdom. Bertrand Russell",
  "People living deeply have no fear of death. Anaïs Nin",
  "I’ll tell you what freedom is to me: no fear.",
  "Fear is temporary. Regrets last forever.",
  "The only thing we have to fear is fear itself. Franklin D. Roosevelt",
  "Thinking will not overcome fear but action will. W. Clement Stone",
  "Curiosity will conquer fear even more than bravery will. James Stephens",
  "The constant assertion of belief is an indication of fear. Jiddu Krishnamurti",
  "Everything you’ve ever wanted is on the other side of fear. George Addair",
  "Nothing in life is to be feared. It is only to be understood. Marie Curie",
  "The mind that is always after explanations is an afraid mind. Osho",
  "Sometimes the fear won’t go away, so you’ll have to do it afraid. Click to tweet",
  "Courage is resistance to fear, mastery of fear, not absence of fear. Mark Twain",
  "Fear has two meanings – Forget Everything And Run or Face Everything And Rise.",
  "Fear is the absence of love. Do something with love, forget about fear. If you love well, fear disappears. Osho",
  "Don’t worry, be happy. Bobby McFerrin",
  "Worry less, smile more.",
  "Pray, and let God worry. Martin Luther",
  "What worries you, masters you. John Locke",
  "Anxiety is the dizziness of freedom. Soren Kierkegaard",
  "It’s ok to worry – it means you care. Click to tweet",
  "The sovereign cure for worry is prayer. William James",
  "Worry often gives a small thing a big shadow. Swedish proverb",
  "Most things I worry about never happen anyway. Tom Petty",
  "You can destroy your now by worrying about tomorrow. Janis Joplin",
  "I never worry about action, but only about inaction. Winston Churchill",
  "As a cure for worrying, work is better than whiskey. Thomas A. Edison",
  "A day of worry is more exhausting than a day of work. John Lubbock",
  "The mind that is anxious about the future is miserable. Lucius Annaeus Seneca",
  "I try not to worry about things I can’t do anything about. Christopher Walken",
  "Worry is a useless mulling over of things we cannot change. Peace Pilgrim",
  "We want predictability, even though it’s impossible to get. Henri Junttila",
  "The problem is that worrying doesn’t actually solve anything. Celestine Chua",
  "Let our advance worrying become advance thinking and planning. Winston Churchill",
  "Throw off your worries when you throw off your clothes at night. Napoleon Bonaparte",
  "Worry never robs tomorrow of its sorrow, it only saps today of its joy. Leo Buscaglia",
  "My life has been full of terrible misfortunes most of which never happened. Michel de Montaigne",
  "Worry is like a rocking chair: it gives you something to do but never gets you anywhere. Erma Bombeck",
  "Stumbling is not falling. Malcolm X",
  "Turn your wounds into wisdom. Oprah Winfrey",
  "Better an ‘oops’ than a ‘what if’.",
  "Failure’s a natural part of life. John Malkovich",
  "Giving up is the greatest failure. Jack Ma",
  "Not failure, but low aim, is crime. James Russell Lowell",
  "If you never try you’ll never know.",
  "I never lose. Either I win or I learn.",
  "Mistakes are the portals of discovery. James Joyce",
  "Avoiding failure is to avoid progress.",
  "My reputation grows with every failure. George Bernard Shaw",
  "Failure is success if we learn from it. Malcolm Forbes",
  "Reward worthy failure – experimentation. Bill Gates",
  "Failure is a detour, not a dead-end street. Zig Ziglar",
  "Experience is the name we give to our mistakes. Oscar Wilde",
  "Remember that failure is an event, not a person. Zig Ziglar",
  "There are no mistakes or failures, only lessons. Denis Waitley",
  "Fear of failure has always been my best motivator. Douglas Wood",
  "Failure is not falling down but refusing to get up. Chinese proverb",
  "You have to be able to accept failure to get better. LeBron James",
  "Failure is not fatal, but failure to change might be. John Wooden",
  "There are some defeats more triumphant than victories. Michel de Montaigne",
  "Failure is the condiment that gives success its flavor. Truman Capote",
  "Success is not a good teacher, failure makes you humble. Shahrukh Khan",
  "The sooner you fail, the less afraid you are to fail again. Brendan Baker",
  "Error is just as important a condition of life as truth. Carl Gustav Jung",
  "Once you can accept failure, you can have fun and success. Rickey Henderson",
  "Testing leads to failure, and failure leads to understanding. Burt Rutan",
  "Ambitious failure, magnificent failure, is a very good thing. Guy Kawasaki",
  "Do not fear mistakes. You will know failure. Continue to reach out. Benjamin Franklin",
  "Life is a succession of lessons which must be lived to be understood. Helen Keller Click to tweet",
  "The season of failure is the best time for sowing the seeds of success. Paramahansa Yogananda",
  "You get knocked down, you get up, brush yourself off, and you get back to work. Barack Obama",
  "I’d rather be a failure at something I love than a success at something I hate. George Burns",
  "The only failure one should fear, is not hugging to the purpose they see as best. George Eliot",
  "Regrets belong to the past. Marlon Brando",
  "Life’s too short for regrets. Nicholas Hoult",
  "Remorse is the poison of life. Charlotte Bronte",
  "You will never regret being kind.",
  "My head has got regrets, but I haven’t. Frank Bruno",
  "There are no regrets in life, just lessons. Jennifer Aniston",
  "Never regret something that made you smile.",
  "Regret for wasted time is more wasted time. Mason Cooley",
  "Fear is only temporary. Regrets last forever. Click to tweet",
  "Regrets are ridiculous, so I don’t regret, no. Nicole Kidman",
  "Regrets are the natural property of grey hairs. Charles Dickens",
  "I have often regretted my speech, never my silence. Publilius Syrus",
  "In the end, we only regret the chances we didn’t take.",
  "Never look back unless you are planning to go that way. Henry David Thoreau",
  "A man is not old until regrets take the place of dreams. John Barrymore",
  "Never make permanent decisions based on temporary feelings.",
  "Before it’s too late, tell them everything you’ve ever wanted to say.",
  "Never regret anything because one time it was exactly what you wanted.",
  "I don’t regret a thing I’ve done. I only regret the things I didn’t do. Ingrid Bergman",
  "Be so good they can’t ignore you. Steve Martin",
  "Be happy. It drives people crazy.",
  "Silence is the best reply to a fool.",
  "The haters always scream the loudest. Tucker Max",
  "They told me I couldn’t. That’s why I did.",
  "I don’t have to be what you want me to be. Muhammad Ali",
  "Your opinion of me doesn’t define who I am.",
  "Hustle until your haters ask if you’re hiring.",
  "Learn not to give a fuck, you will be happier.",
  "To refrain from imitation is the best revenge. Marcus Aurelius",
  "Have you ever met a hater doing better than you?",
  "I destroy my enemies when I make them my friends. Abraham Lincoln",
  "You never look good trying to make someone look bad.",
  "Ask for help, they laugh. Do it yourself, they hate.",
  "Haters keep on hating, cause somebody’s gotta do it. Chris Brown",
  "Always forgive your enemies. Nothing annoys them so much. Oscar Wilde Click to tweet",
  "Your love makes me strong, your hate makes me unstoppable. Cristiano Ronaldo",
  "If they don’t know you personally, don’t take it personal.",
  "You pick up some fans and a handful of haters along the way. Bruno Mars",
  "The best revenge is to be unlike him who performed the injury. Marcus Aurelius",
  "Dear haters, I’m flattered that I’m a trending topic in your life.",
  "Whenever you are confronted with an opponent. Conquer him with love. Mohandas Gandhi",
  "Don’t worry about those who talk behind your back, they’re behind you for a reason.",
  "A hater is nothing but a fan. They just don’t know how to express it in positive way. Bar Refaeli",
  "Haters don’t really hate you, they hate themselves. You’re a reflection of what they wish to be.",
  "I’m thankful for all those difficult people in my life, they have shown me exactly who I don’t want to be.",
  "Doubts are death. Maharishi Mahesh Yogi",
  "Inactivity is death. Benito Mussolini",
  "Security is a kind of death. Tennessee Williams",
  "My life, my death, my choice. Terry Pratchett",
  "Death is a debt we all must pay. Euripides",
  "Death is the final wake-up call. Douglas Horton",
  "Death, only, renders hope futile. Edgar Rice Burroughs",
  "Every man dies. Not every man lives. William Wallace",
  "Death is the beginning of something. Edith Piaf",
  "Our business is with life, not death. George Wald",
  "I don’t want to die without any scars. Chuck Palahniuk",
  "Death is a distant rumor to the young. Andrew A. Rooney",
  "Death is just life’s next big adventure. J. K. Rowling",
  "Enjoy life now – one day you’ll be dead.",
  "He who doesn’t fear death dies only once. Giovanni Falcone",
  "Grief does not change you, it reveals you. John Green",
  "Death is frightening, and so is Eternal Life. Mason Cooley",
  "The idea is to die young as late as possible. Ashley Montagu",
  "I would rather die of passion than of boredom. Vincent van Gogh",
  "You cannot save people, you can only love them.",
  "I feel monotony and death to be almost the same. Charlotte Bronte",
  "If by my life or death I can protect you, I will. J. R. R. Tolkien",
  "Guilt is perhaps the most painful companion of death. Coco Chanel",
  "A man who won’t die for something is not fit to live. Martin Luther King Jr",
  "A man who lives fully is prepared to die at any time. Mark Twain",
  "Do not fear death so much but rather the inadequate life. Bertolt Brecht",
  "The death of something can become your greatest strength.",
  "A thing is not necessarily true because a man dies for it. Oscar Wilde",
  "Even death is not to be feared by one who has lived wisely. Buddha",
  "I have no fear of death. More important, I don’t fear life. Steven Seagal",
  "Our dead are never dead to us, until we have forgotten them. George Eliot",
  "My fear was not of death itself, but a death without meaning. Huey Newton",
  "Don’t take life too seriously. You’ll never get out of it alive. Elbert Hubbard Click to tweet",
  "Death is the only pure, beautiful conclusion of a great passion. D. H. Lawrence",
  "Take care of your life and the Lord will take care of your death. George Whitefield",
  "To the well-organized mind, death is but the next great adventure. J.K. Rowling",
  "If life must not be taken too seriously, then so neither must death. Samuel Butler",
  "It is not death or pain that is to be dreaded, but the fear of pain or death. Epictetus",
  "It is the unknown we fear when we look upon death and darkness, nothing more. J.K. Rowling",
  "We all die. The goal isn’t to live forever, the goal is to create something that will. Chuck Palahniuk",
  "Life is too short to wait. Click to tweet",
  "Life is too short to worry.",
  "Well, you and I only have one life.",
  "Life is too short to be little. Benjamin Disraeli",
  "Life is too short to be serious.",
  "Life is too short to be miserable. Rita Mae Brown",
  "Life is too short not to experiment. Jamelia",
  "Life is short. Do stuff that matters.",
  "Life is too short to even care at all.",
  "Life is a one-time offer. Use it well.",
  "Smile. Life is too short to be unhappy.",
  "Don’t wait. Life goes faster than you think.",
  "Time flies, whether you’re wasting it or not. Crystal Woods",
  "Go for it now. The future is promised to no one. Wayne Dyer",
  "Expect an early death – it will keep you busier. Martin H. Fischer",
  "Life is short. Smile while you still have teeth.",
  "Life is too short to spend it at war with yourself.",
  "Life is so, so short. Bible says it’s like a vapor. Muhammad Ali",
  "Life is too short to harbor any hostilities towards anybody. Peabo Bryson",
  "Holding a grudge is letting someone live rent-free in your head.",
  "Be patient and understanding. Life is too short to be vengeful or malicious. Phillips Brooks",
  "Life is too short to hide your feelings. Don’t be afraid to say what you feel.",
  "Life is so short, so beautiful. Don’t be so serious about the work. Enjoy the life. Jack Ma Click to tweet",
  "Life is too short to worry about stupid things. Have fun. Regret nothing, and don’t let people bring you down."
];

export default quotes;